import React from "react";
import styled from "styled-components";

function AssistantsTableHead() {
	return (
		<SectionContainer>
			<RowDiv>
				<Columns type={"no"}>No</Columns>
				<Columns type={"name"}>Name</Columns>
				<Columns type={"school"}>School</Columns>
				<Columns type={"class"}>Class</Columns>
				<Columns type={"date"}>Date & Time</Columns>
			</RowDiv>
		</SectionContainer>
	);
}

export default AssistantsTableHead;

const SectionContainer = styled.div`
	padding: 0 16px;
	border-bottom: 1px solid #e2e2e2;
`;
const RowDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 45px;
	color: #7f7d7d;
	font-family: "Inter-Medium";
`;
const Columns = styled.div`
	width: ${({ type }) =>
		type == "no"
			? "5%"
			: type == "name"
			? "20%"
			: type == "school"
			? "20%"
			: type == "class"
			? "10%"
			: type == "date"
			? "20%"
			: "15%"};
`;
const Download = styled.span`
	display: inline-block;
	width: 20px;
	img {
		width: 100%;
		display: block;
	}
`;
const Dots = styled.span`
	display: inline-block;
	width: 20px;
	img {
		width: 100%;
		display: block;
	}
`;
