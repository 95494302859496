import React from "react";
import styled from "styled-components";

function TabSwitcher({ tabState, setTabState }) {
    return (
        <MainContainer>
            <ContentBox>
                <TopContainer>
                    <TabBox>
                        <TopBox>
                            <ButtonName
                                className={
                                    tabState == "participants" ? "active" : ""
                                }
                                onClick={() => {
                                    setTabState("participants");
                                }}
                            >
                                Participants
                            </ButtonName>
                            <ButtonName
                                className={
                                    tabState == "applications" ? "active" : ""
                                }
                                onClick={() => {
                                    setTabState("applications");
                                }}
                            >
                                Applications
                            </ButtonName>
                        </TopBox>
                    </TabBox>
                    {/* <RightBox>
                        <RightText>View All</RightText>
                        <Arrow>
                            <img
                                src={
                                    require("../../../assets/icons/active-right-circle.svg")
                                        .default
                                }
                            />
                        </Arrow>
                    </RightBox> */}
                </TopContainer>
            </ContentBox>
        </MainContainer>
    );
}

export default TabSwitcher;

const MainContainer = styled.section`
    border-bottom: 1px solid #e2e2e2;
    padding: 8px 15px;
`;
const ContentBox = styled.div`
    /* background: #fff;
    padding: 20px;
    border-radius: 16px;
    */
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TabBox = styled.div``;
const RightBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const RightText = styled.span`
    color: #0fa76f;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
`;
const Arrow = styled.div`
    display: inline-block;
    cursor: pointer;

    img {
        display: block;
        width: 100%;
    }
`;

const TopBox = styled.div`
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    padding: 13px 10px;
`;
const ButtonName = styled.span`
    padding: 7px 12px;
    text-align: center;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 10px;
    color: #000;
    font-family: Inter-Regular;
    background: #fff;
    font-size: 14px;
    &.active {
        color: white;
        background-color: #0fa76f;
        box-shadow: 0px 8px 9px rgba(150, 202, 75, 0.29);
    }
    :last-child{
        margin-right: 0;
    }
`;
