import React from "react";
import styled from "styled-components";

function AssistantsStudents({ studentDetails }) {
	return (
		<SectionContainer>
			{studentDetails.map((student, index) => (
				<RowDiv key={index}>
					<Columns type={"no"}>{index + 1}</Columns>
					<Columns type={"name"}>{student.student_name}</Columns>
					<Columns type={"school"}>{student.school}</Columns>
					<Columns type={"class"}>{student.class}</Columns>
					<Columns type={"date"}>{student.date_time}</Columns>
				</RowDiv>
			))}
		</SectionContainer>
	);
}

export default AssistantsStudents;

const SectionContainer = styled.div``;
const RowDiv = styled.div`
	padding: 0 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #f4f4f4;
	height: 45px;
`;
const Columns = styled.div`
	width: ${({ type }) =>
		type == "no"
			? "5%"
			: type == "name"
			? "20%"
			: type == "school"
			? "20%"
			: type == "class"
			? "10%"
			: type == "date"
			? "20%"
			: "15%"};
	color: #333;
`;
