import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OutLineButton from "./OutLineButton";
import Avatar from "react-avatar";
import EditDetails from "../modals/EditDetails";

function ProfileWidget({ profile, manager, setReload }) {
    const [maxWidthReached, setMaxWidthReached] = useState(true);
    const [EditModal, setEditModal] = useState(false);
    const [showModal, setModal] = useState(false);
    const [editUser, setEditUser] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 480) {
                setMaxWidthReached(false);
            } else {
                setMaxWidthReached(true);
            }
        };
        if (window.innerWidth <= 480) {
            setMaxWidthReached(false);
        } else {
            setMaxWidthReached(true);
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <>
            <EditDetails
                setReload={setReload}
                EditModal={EditModal}
                setEditModal={setEditModal}
                userType={manager.user_type}
                chiefID={manager.pk}
                editUser={editUser}
            />
            <MainContainer>
                <Container>
                    <ContentBox>
                        <ProfileDiv>
                            {manager?.photo ? (
                                <ImageDiv>
                                    <img src={manager.photo} alt="Profile" />
                                </ImageDiv>
                            ) : (
                                <AvatarBox>
                                    <Avatar
                                        size="48"
                                        round={true}
                                        textSizeRatio={0.5}
                                        name={manager?.name}
                                    />
                                </AvatarBox>
                            )}
                            <NameDiv>
                                <Name>{manager.name ? manager.name : ""}</Name>
                                <Number>
                                    {manager.phone ? manager.phone : "--"}
                                </Number>
                            </NameDiv>
                        </ProfileDiv>

                        <DetailDiv>
                            <NameBox>
                                <MainName>Username</MainName>
                                <SubName>
                                    {manager.username ? manager.username : "--"}
                                </SubName>
                            </NameBox>
                            <NameBox>
                                <MainName>Phone Number</MainName>
                                <SubName>
                                    {manager.phone ? manager.phone : "--"}
                                </SubName>
                            </NameBox>
                            <NameBox>
                                <MainName>Email Address</MainName>
                                <SubName>
                                    {manager.email ? manager.email : "--"}
                                </SubName>
                            </NameBox>
                            <NameBox>
                                <MainName>College</MainName>
                                <SubName>
                                    {manager.college ? manager.college : "--"}
                                </SubName>
                            </NameBox>
                            <NameBox>
                                <MainName>Branch</MainName>
                                <SubName>
                                    {manager.branch ? manager.branch : "--"}
                                </SubName>
                            </NameBox>
                            <NameBox>
                                <MainName>Year of study</MainName>
                                <SubName>
                                    {manager.year_of_study
                                        ? manager.year_of_study
                                        : "--"}
                                </SubName>
                            </NameBox>
                            {(profile === "program_assistant" ||
                                profile === "program_associate" ||
                                profile === "program_executive" ||
                                profile === "program_manager") && (
                                <NameBox>
                                    <MainName>District</MainName>
                                    <SubName>
                                        {manager.district
                                            ? manager.district
                                            : "--"}
                                    </SubName>
                                </NameBox>
                            )}
                            {/* {profile === "program_assistant" && (
								<NameBox>
									<MainName>Panchayath</MainName>
									<SubName>
										{manager.panchayath
											? manager.panchayath
											: "--"}
									</SubName>
								</NameBox>
							)} */}
                            {profile === "program_assistant" && (
                                <NameBox>
                                    <MainName>Wards</MainName>
                                    <SubName>
                                        {manager.ward ? manager.ward : "--"}
                                    </SubName>
                                </NameBox>
                            )}
                            {/* {profile === "program_associate" && (
                                <NameBox>
                                    <MainName>Corporation</MainName>
                                    <SubName>
                                        {manager.corporation
                                            ? manager.corporation
                                            : "--"}
                                    </SubName>
                                </NameBox>
                            )} */}
                        </DetailDiv>
                    </ContentBox>
                    <ChatBox>
                        <ChatDiv
                            href={`https://wa.me/+91${manager?.phone}`}
                            target="_blank"
                        >
                            <OutLineButton
                                icon="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/whatsapp.svg"
                                content={maxWidthReached ? "Chat Now" : ""}
                            />
                        </ChatDiv>
                    </ChatBox>
                    <EditButton
                        onClick={() => {
                            setEditModal(!EditModal);
                            setEditUser(!editUser);
                        }}
                    >
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/edit-pen.svg"
                            alt="Edit"
                        />
                    </EditButton>
                </Container>
            </MainContainer>
        </>
    );
}

export default ProfileWidget;

const ChatBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 7%;
    right: 6%;
    @media all and (max-width: 980px) {
        right: 7%;
    }
    @media all and (max-width: 768px) {
        right: 8%;
    }
    @media all and (max-width: 640px) {
        right: 10%;
        top: 4%;
    }
    @media all and (max-width: 480px) {
        right: 13%;
    }
`;
const EditButton = styled.span`
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 4px;
    right: 2%;
    top: 7%;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 640px) {
        top: 4%;
    }
`;
const ChatDiv = styled.a`
    display: block;
`;

const DotsButton = styled.div`
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;

const MainContainer = styled.section``;
const Container = styled.div`
    background: #fff;
    padding: 15px;
    margin-top: 20px;
    border-radius: 16px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media all and (max-width: 980px) {
        align-items: flex-end;
    }
    @media all and (max-width: 640px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    @media all and (max-width: 480px) {
        padding: 10px;
        flex-direction: unset;
    }
`;
const ContentBox = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    @media all and (max-width: 1280px) {
        /* flex-direction: column;
        align-items: stretch; */
    }
    @media all and (max-width: 980px) {
        margin-bottom: unset;
    }
    @media all and (max-width: 768px) {
        justify-content: unset;
    }
    @media all and (max-width: 640px) {
        /* flex-direction: column; */
        /* align-items: stretch; */
    }
`;
const ProfileDiv = styled.div`
    display: flex;
    align-items: center;
    width: 200px;
    padding-right: 20px;
    margin-bottom: 20px;
    @media all and (max-width: 640px) {
        margin-bottom: 12px;
    }
`;
const ImageDiv = styled.div`
    margin-right: 15px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
    overflow: hidden;

    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 1280px) {
        margin-right: 15px;
    }
`;
const AvatarBox = styled.div`
    margin-right: 15px;
    & div.sb-avatar__text {
        div {
            font-size: 18px !important;
        }
    }
`;
const DetailDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media all and (max-width: 768px) {
    }
`;
const NameDiv = styled.div``;
const Name = styled.h1`
    font-size: 17px;
    color: black;
    margin-bottom: 5px;
    font-family: Inter-Medium;
    text-transform: capitalize;
    white-space: nowrap;
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;
const Number = styled.span`
    font-size: 14px;
    color: #969696;
    white-space: nowrap;
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const NameBox = styled.div`
    padding: 0 35px;
    border-left: 1px solid #d3d3d3;
    margin-bottom: 15px;

    @media all and (max-width: 980px) {
        padding: 0 20px;
    }
    @media all and (max-width: 768px) {
        width: 33%;
        border: 0;
        margin-bottom: 10px;
        padding: 0;
        /* :nth-child(4),
        :nth-child(3) {
            margin-bottom: 0;
        } */
    }

    @media all and (max-width: 640px) {
        padding: 0 24px;
        width: 48%;
        margin-bottom: 15px;
    }
    @media all and (max-width: 480px) {
        padding: 0 8px;
    }
`;
const MainName = styled.h1`
    font-size: 14px;
    margin-bottom: 8px;
    color: #969696;
    font-family: Inter-Medium;
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const SubName = styled.span`
    font-size: 14px;
    color: black;
    font-family: Inter-Medium;
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
