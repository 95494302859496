import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import ProfileWidget from "../../includes/profile/ProfileWidget";
import ShareScholarship from "../../includes/profile/ShareScholarship";
import AssistantsStudents from "../../includes/teams/program-assistants/AssistantsStudents";
import AssistantsTableHead from "../../includes/teams/program-assistants/AssistantsTableHead";
import CongratulationsCard from "../../includes/teams/program-assistants/CongratulationsCard";
import ExaminationDetail from "../../includes/teams/ExaminationDetail";
import { textStyle, COLORS } from "../../utils/constants";
import { axiosConfig } from "../../../axiosConfig";
import { useParams, useSearchParams } from "react-router-dom";
import { Context } from "../../context/Store";
import ProgramCounts from "../../includes/teams/ProgramCounts";
import TabSwitcher from "../../includes/profile/TabSwitcher";
import PageTitle from "../../includes/PageTitle";
import SectionLoader from "../../includes/loaders/SectionLoader";
function ProgramAssistantsSingle() {
    const {
        state: {
            user_data: { profile_pk },
        },
    } = useContext(Context);
    const [studentDetails, setStudentDetails] = useState([]);
    const [isDetailsLoading, setDetailsLoading] = useState(false);
    const [programAssistant, setProgramAssistant] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [posters, setPosters] = useState([
        {
            id: 1,
            poster: require("../../../assets/images/posters/poster1.jpeg"),
        },
        {
            id: 2,
            poster: require("../../../assets/images/posters/poster2.jpeg"),
        },
        {
            id: 3,
            poster: require("../../../assets/images/posters/poster3.jpeg"),
        },
        {
            id: 4,
            poster: require("../../../assets/images/posters/poster4.jpeg"),
        },

        {
            id: 5,
            poster: require("../../../assets/images/posters/poster5.jpeg"),
        },
        {
            id: 6,
            poster: require("../../../assets/images/posters/poster6.jpeg"),
        },
    ]);
    const [tabState, setTabState] = useState("participants");
    const [counts, setCounts] = useState({});
    const [isReload, setReload] = useState(false);
    const { state } = useContext(Context);
    const access_token = state.user_data.access_token; 
    const searchParams = useSearchParams();
    const { id } = useParams();

    const getPosters = () => {
        setLoading(true);
        axiosConfig
            .get("/programs/view-posters/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // setPosters(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };
    const getApplications = () => {
        setDetailsLoading(true);
        axiosConfig
            .get(
                `programs/applications/?chief_user_pk=${id ? id : profile_pk}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetails(data);
                    setDetailsLoading(false);
                } else {
                    setDetailsLoading(false);
                }
            })
            .catch((err) => {
                setDetailsLoading(false);
                console.log(err);
            });
    };
    const getParticipants = () => {
        setDetailsLoading(true);
        axiosConfig
            .get(
                `programs/participants/?chief_user_pk=${id ? id : profile_pk}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetails(data);
                    setDetailsLoading(false);
                } else {
                    setDetailsLoading(false);
                }
            })
            .catch((err) => {
                setDetailsLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        if (tabState === "participants") {
            getParticipants();
        } else {
            getApplications();
        }
    }, [tabState]);
    useEffect(() => {
        axiosConfig
            .get(`accounts/chief-user/${id ? id : profile_pk}/view/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setProgramAssistant(data);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, [isReload]);
    useEffect(() => {
        setLoading(true);
        axiosConfig
            .get(`accounts/chief-user/count/${id ? id : profile_pk}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { data, StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setCounts(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, []);

    return (
        <MainContainer id="main">
            <Container>
                <PageTitle
                    name={programAssistant.name}
                    tab="Home"
                    section="Team"
                    designation="Program Assistants"
                />
                <ProfileWidget
                    profile="program_assistant"
                    manager={programAssistant}
                    setReload={setReload}
                />
                <Cover className="inner-wrapper">
                    <MiniCover>
                        <ShareContainer>
                            <ShareScholarship
                                posters={posters}
                                isLoading={isLoading}
                            />
                        </ShareContainer>
                        <ProgrameDiv>
                            <TabSwitcher
                                tabState={tabState}
                                setTabState={setTabState}
                            />
                            <Bottom>
                                <BottomScroll>
                                    <TableHead>
                                        <AssistantsTableHead />
                                    </TableHead>
                                    {isDetailsLoading ? (
                                        <EmptyBox>
                                            <SectionLoader />
                                        </EmptyBox>
                                    ) : studentDetails.length > 0 ? (
                                        <StudentsDiv>
                                            <AssistantsStudents
                                                studentDetails={studentDetails}
                                            />
                                        </StudentsDiv>
                                    ) : (
                                        <EmptyBox>No Data Found!</EmptyBox>
                                    )}
                                </BottomScroll>
                            </Bottom>
                        </ProgrameDiv>
                    </MiniCover>
                    <MiniCover>
                        <ContainerDiv>
                            <CongratulationsDiv>
                                <CongratulationsCard />
                            </CongratulationsDiv>
                            <Box>
                                <CountDiv>
                                    <ProgramCounts
                                        manager="program_assistant"
                                        counts={counts}
                                        profile={programAssistant}
                                    />
                                </CountDiv>
                                <ExaminationCompletedDiv>
                                    <ExaminationDetail />
                                </ExaminationCompletedDiv>
                            </Box>
                        </ContainerDiv>
                    </MiniCover>
                </Cover>
            </Container>
        </MainContainer>
    );
}

export default ProgramAssistantsSingle;
const MainContainer = styled.div`
    @media screen {
    }
`;
const Container = styled.div`
    width: 100%;
    @media all and (max-width: 1280px) {
        min-width: 780px;
    }
    @media all and (max-width: 980px) {
        min-width: 0;
    }
`;
const ShareContainer = styled.div`
    margin: 24px 0;
    @media all and (max-width: 480px) {
        margin: 12px 0px;
    }
`;

const Cover = styled.div`
    grid-template-columns: 8fr 4fr !important;
    @media all and (max-width: 980px) {
        grid-template-columns: none !important;
    }
    @media all and (max-width: 768px) {
        display: unset !important;
    }
`;
const MiniCover = styled.div`
    &:first-child {
        margin-bottom: 25px;
    }
    @media all and (max-width: 480px) {
        &:first-child {
            margin-bottom: 10px;
        }
    }
`;
const ContainerDiv = styled.div`
    display: flex;
    flex-direction: column;

    @media all and (max-width: 980px) {
        flex-direction: unset;
    }
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const Box = styled.div`
    display: flex;
    flex-direction: column;
    @media all and (max-width: 980px) {
        width: 40%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const TableContainer = styled.div`
    @media all and (max-width: 640px) {
        min-width: 530px;
    }
`;

const ProgrameDiv = styled.div`
    background: #fff;
    border-radius: 10px;
    border: 1px solid #e2e2e2;
    &::-webkit-scrollbar {
        height: 5px;
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${COLORS.steyp.primary};
        border-radius: 5px;
    }
    @media all and (max-width: 640px) {
        overflow-x: scroll;
        max-width: 94vw;
    }
`;
const Bottom = styled.div`
    max-height: 350px;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    div {
    }
`;
const BottomScroll = styled.div`
    min-width: 600px;
    overflow-x: scroll;
`;
const TableHead = styled.div``;
const StudentsDiv = styled.div``;

const CongratulationsDiv = styled.div`
    margin: 24px 0;
    @media all and (max-width: 980px) {
        margin: 0;
        margin-right: 20px;
        width: 58%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
    }
    @media all and (max-width: 480px) {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 10px;
    }
`;
const ExaminationCompletedDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @media all and (max-width: 980px) {
        flex-direction: column-reverse;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }

    /* margin-top: 24px; */
`;
const EmptyBox = styled.div`
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: "Inter-Medium";
`;
const CountDiv = styled.div`
    width: 100%;
    margin-bottom: 24px;
    @media all and (max-width: 480px) {
        margin-bottom: 10px;
    }
`;

