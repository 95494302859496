import React from "react";
import styled from "styled-components";

function ShareButton({ sharingFile }) {
  return (
    <ShareButtonDiv href={sharingFile} target="_blank">
      <ShareButtonImage>
        <img src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/share.svg' />
      </ShareButtonImage>
    </ShareButtonDiv>
  );
}

export default ShareButton;

const ShareButtonDiv = styled.a`
  padding: 6px 8px;
  background-color: #0fa76f;
  cursor: pointer;
  border-radius: 8px;
  display: inline-block;
`;

const ShareButtonImage = styled.span`
  display: inline-block;
  width: 110%;
  img {
    display: block;
    width: 100%;
  }
`;
