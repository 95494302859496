import React from "react";
import styled from "styled-components";
import { textStyle } from "../utils/constants";
import CountUp from "react-countup";

function PageTitle({ name, tab, section, designation, count }) {
    return (
        <HeaderComponent>
            <h3>
                {name ? name : ""}{" "}
                {count ? <>({<CountUp end={count} duration={1} start={0} />})</> : ""}
            </h3>
            <TitleTab>
                <h4>
                    {tab && (
                        <>
                            <span>{tab}</span>
                            <span>/</span>
                        </>
                    )}
                    {section && (
                        <>
                            <span>{section}</span>
                            <span>/</span>
                        </>
                    )}
                    {designation && (
                        <>
                            <span className="bold"> {designation}</span>
                        </>
                    )}
                </h4>
            </TitleTab>
        </HeaderComponent>
    );
}

export default PageTitle;
const HeaderComponent = styled.div`
    h3 {
        margin-bottom: 10px;
        font-family: "Inter-Medium";
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        text-transform: capitalize;
    }
`;

const TitleTab = styled.div`
    h4 {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 18px;
        font-family: "Inter-Medium";
    }
    span {
        font-family: "Inter-Medium";
        color: #333;
        opacity: 0.6;
        &:first-child {
            margin-left: 0;
        }
        &:nth-child(2n) {
            margin: 0 5px;
        }
        &.bold {
            opacity: 1;
        }
    }
`;
