import React from "react";
import styled from "styled-components";
import CountUp from "react-countup";

function GeneralHeading({ title, count }) {
    return (
        <Heading>
            <span>
                {title}
                {count ? (
                    <>
                        (<CountUp end={count} duration={1} start={0} />)
                    </>
                ) : (
                    ""
                )}
            </span>
        </Heading>
    );
}

export default GeneralHeading;

const Heading = styled.h1`
    color: #333333;
    position: relative;
    margin-bottom: 15px;
    height: 30px;
    &::before {
        content: "";
        left: 0;
        top: 18px;
        position: absolute;
        width: 100%;
        border-top: 1px solid #e2e2e2;
    }
    span {
        background: #fff;
        display: inline-block;
        z-index: 2;
        position: relative;
        padding-right: 6px;
        font-size: 18px;
        font-family: "Inter-Medium";
        @media all and (max-width: 1080px) {
            font-size: 16px;
        }
        @media all and (max-width: 768px) {
            font-size: 15px;
        }
        @media all and (max-width: 640px) {
            font-size: 17px;
        }
    }
`;
