import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { axiosConfig } from "../../../axiosConfig";
import { useParams } from "react-router-dom";
import { Context } from "../../context/Store";
import SectionLoader from "../loaders/SectionLoader";
import Avatar from "react-avatar";

function ParticipantsSingle() {
    const { state } = useContext(Context);
    const access_token = state.user_data?.access_token;
    const [isLoading, setLoading] = useState(false);
    const [studentData, setStudentData] = useState({});
    const [allData, setAllData] = useState({})
    const [questions, setQuestions] = useState([]);
    const { id } = useParams();
    const [dateObj, setDateObj] = useState(new Date());
    useEffect(() => {
        if (studentData) {
            let timestamp = '2023-04-30T00:00:00.00Z'
            timestamp = allData?.end_timestamp;
            setDateObj(
                new Date(
                    Date.UTC(
                        parseInt(timestamp?.substring(0, 4)), // Year
                        parseInt(timestamp?.substring(5, 7)) - 1, // Month (zero-based)
                        parseInt(timestamp?.substring(8, 10)), // Day
                        parseInt(timestamp?.substring(11, 13)), // Hour
                        parseInt(timestamp?.substring(14, 16)), // Minute
                        parseInt(timestamp?.substring(17, 19)), // Second
                        parseInt(timestamp?.substring(20, 23)) // Milliseconds
                    )
                )
            );
        }
    }, [studentData]);
    useEffect(() => {
        setLoading(true);
        axiosConfig
            .get(`exams/view-exam-details/${id}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                const { student_questions, student_details } = data;
                if (StatusCode === 6000) {
                    setStudentData(student_details);
                    setAllData(data)
                    setLoading(false);
                    setQuestions(student_questions);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, []);

    return (
        <div id="main">
            <MainContainer className="inner-wrapper">
                <MiniCover >
                    <LeftContainer>
                        {isLoading ? (
                            <LoaderDiv>
                                <SectionLoader />
                            </LoaderDiv>
                        ) : (
                            questions.length > 0 &&
                            questions.map((q, index) => (
                                <QuestionContainer key={index}>
                                    <QuestionHeading>{`Question ${
                                        index + 1
                                    }`}</QuestionHeading>
                                    <Paragraph>
                                        {q.question_details?.question}
                                    </Paragraph>
                                    <QuestionSection
                                        className={
                                            "option1" ===
                                                q.question_details
                                                    .right_option &&
                                            "option1" === q.selected_option
                                                ? "student_correct"
                                                : "option1" ===
                                                      q.question_details
                                                          .right_option &&
                                                  "option1" !==
                                                      q.selected_option
                                                ? "correct"
                                                : "option1" !==
                                                      q.question_details
                                                          .right_option &&
                                                  "option1" ===
                                                      q.selected_option
                                                ? "error"
                                                : ""
                                        }
                                    >
                                        <Option>A</Option>
                                        <QuestionAnswer>
                                            {q.question_details.option1}
                                        </QuestionAnswer>
                                    </QuestionSection>
                                    <QuestionSection
                                        className={
                                            "option2" ===
                                                q.question_details
                                                    .right_option &&
                                            "option2" === q.selected_option
                                                ? "student_correct"
                                                : "option2" ===
                                                      q.question_details
                                                          .right_option &&
                                                  "option2" !==
                                                      q.selected_option
                                                ? "correct"
                                                : "option2" !==
                                                      q.question_details
                                                          .right_option &&
                                                  "option2" ===
                                                      q.selected_option
                                                ? "error"
                                                : ""
                                        }
                                    >
                                        <Option>B</Option>
                                        <QuestionAnswer>
                                            {q.question_details.option2}
                                        </QuestionAnswer>
                                    </QuestionSection>
                                    <QuestionSection
                                        className={
                                            "option3" ===
                                                q.question_details
                                                    .right_option &&
                                            "option3" === q.selected_option
                                                ? "student_correct"
                                                : "option3" ===
                                                      q.question_details
                                                          .right_option &&
                                                  "option3" !==
                                                      q.selected_option
                                                ? "correct"
                                                : "option3" !==
                                                      q.question_details
                                                          .right_option &&
                                                  "option3" ===
                                                      q.selected_option
                                                ? "error"
                                                : ""
                                        }
                                    >
                                        <Option>C</Option>
                                        <QuestionAnswer>
                                            {q.question_details.option3}
                                        </QuestionAnswer>
                                    </QuestionSection>
                                    <QuestionSection
                                        className={
                                            "option4" ===
                                                q.question_details
                                                    .right_option &&
                                            "option4" === q.selected_option
                                                ? "student_correct"
                                                : "option4" ===
                                                      q.question_details
                                                          .right_option &&
                                                  "option4" !==
                                                      q.selected_option
                                                ? "correct"
                                                : "option4" !==
                                                      q.question_details
                                                          .right_option &&
                                                  "option4" ===
                                                      q.selected_option
                                                ? "error"
                                                : ""
                                        }
                                    >
                                        <Option>D</Option>
                                        <QuestionAnswer>
                                            {q.question_details.option4}
                                        </QuestionAnswer>
                                    </QuestionSection>
                                </QuestionContainer>
                            ))
                        )}
                    </LeftContainer>
                </MiniCover>
                <MiniCover>
                    <RightContainer>
                        <ProfileContainer>
                            <NameSection>
                                {studentData.photo ? (
                                <ProfilePhoto>
                                    <img src={studentData.photo} alt="Icon" />
                                </ProfilePhoto>
                            ) : (
                                <AvatarBox>
                                    <Avatar
                                        round={true}
                                        name={studentData?.name}
                                        textSizeRatio={0.5}
                                        size="55"
                                    />
                                </AvatarBox>
                            )}

                                <Name>
                                    {studentData ? studentData?.name : ""}
                                </Name>
                                <IdName>
                                    {studentData
                                        ? studentData?.admission_number
                                        : ""}
                                </IdName>
                            </NameSection>
                        </ProfileContainer>
                        <ScoreBoard>
                            <ExamScore>Exam Score</ExamScore>
                            <ExamMark>
                                {allData ? allData.score : ""}
                            </ExamMark>
                        </ScoreBoard>
                        <PersonalInformationSection>
                            <Heading>Personal information</Heading>
                            <Information>
                                <PersonalData>
                                    <SmallHeading>Class</SmallHeading>
                                    <Content>
                                        {studentData
                                            ? `${studentData?.student_class} ${studentData?.student_division}`
                                            : ""}
                                    </Content>
                                </PersonalData>
                                <PersonalData>
                                    <SmallHeading>Time</SmallHeading>
                                    <Content>
                                        {studentData
                                            ? `${dateObj.getUTCHours()}hrs ${dateObj.getUTCMinutes()}min ${dateObj.getUTCSeconds()}sec`
                                            : ""}
                                    </Content>
                                </PersonalData>
                                <PersonalData>
                                    <SmallHeading>School</SmallHeading>
                                    <Content>
                                        {studentData
                                            ? studentData?.school_name
                                            : ""}
                                    </Content>
                                </PersonalData>
                                <PersonalData>
                                    <SmallHeading>Phone No</SmallHeading>
                                    <Content>
                                        {studentData ? studentData?.phone : ""}
                                    </Content>
                                </PersonalData>
                                <PersonalData>
                                    <SmallHeading>Category</SmallHeading>
                                    <Content>HSS</Content>
                                </PersonalData>
                                <PersonalData>
                                    <SmallHeading>Ward</SmallHeading>
                                    <Content>
                                        {studentData ? studentData?.ward : ""}
                                    </Content>
                                </PersonalData>
                                <PersonalData>
                                    <SmallHeading>Local body</SmallHeading>
                                    <Content>
                                        {studentData ? studentData.localbody : ""} <br />
                                        {studentData
                                            ? studentData?.localbody_type?.replace("_", ' ')
                                            : ""}
                                    </Content>
                                </PersonalData>
                                <PersonalData>
                                    <SmallHeading>District</SmallHeading>
                                    <Content>
                                        {studentData
                                            ? studentData?.district
                                            : ""}
                                    </Content>
                                </PersonalData>
                            </Information>
                        </PersonalInformationSection>
                    </RightContainer>
                </MiniCover>
            </MainContainer>
        </div>
    );
}

export default ParticipantsSingle;

const MiniCover = styled.div`
    /* max-height: calc(100vh - 80px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    } */
`
const MainContainer = styled.div`
    grid-template-columns: 8fr 4fr !important;
    @media all and (max-width: 980px) {
        display: flex !important;
        flex-direction: column-reverse;
        grid-template-columns: none !important;
    }
`;
const LeftContainer = styled.div``;
const QuestionContainer = styled.div`
    background: #ffffff;
    border: 2px solid #e2e2e2;
    border-radius: 16px;
    /* width: 58%; */
    padding: 25px;
    margin-bottom: 20px;
    span {
        font-family: Inter-Medium;
        font-size: 14px;
        margin-left: 10px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;

const ProfilePhoto = styled.div`
    margin-bottom: 10px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        display: block;
    }
`;
const AvatarBox = styled.div`
    margin-bottom: 10px;
    & div.sb-avatar__text {
        div {
            font-size: 18px !important;
        }
    }
`;
const QuestionContainerTwo = styled.div``;
const QuestionHeading = styled.h2`
    color: #686868;
    font-family: Inter-Medium;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
`;
const Paragraph = styled.p`
    color: #000;
    font-family: Inter-Medium;
    font-size: 14px;
    margin-bottom: 10px;
`;
const Option = styled.span`
    background: #fafafa;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 16px;
    font-family: Inter-Medium;
`;
const QuestionAnswer = styled.span`
    display: inline-block;
    color: #333333;
`;
const QuestionSection = styled.div`
    display: flex;
    background: #ffffff;
    border: 1px solid #f4f4f4;
    border-radius: 8px;
    padding: 15px;
    margin-top: 15px;
    align-items: center;
    cursor: pointer;
    &.correct {
        border: 1px solid #0fa76f;
        ${Option} {
            color: #0fa76f;
        }
        ${QuestionAnswer} {
            color: #0fa76f;
        }
    }
    &.error {
        background: #ff0000;
        ${Option} {
            color: #333333;
        }
        ${QuestionAnswer} {
            color: #fff;
        }
    }
    &.student_correct {
        background-color: #0fa76f;
        ${Option} {
            color: #000;
        }
        ${QuestionAnswer} {
            color: #fff;
        }
    }
`;

const RightContainer = styled.div`
    background: #ffffff;
    border: 2px solid #e2e2e2;
    border-radius: 16px;
    /* width: 40%; */
    padding: 25px;
`;
const ProfileContainer = styled.div``;
const NameSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 0px;
`;
const ProfileImage = styled.div`
    width: 70px;
    margin-bottom: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const Name = styled.h1`
    color: #333333;
    font-size: 18px;
    font-family: Inter-Medium;
    margin-bottom: 10px;
`;
const IdName = styled.span`
    color: #0fa76f;
    font-size: 16px;
    font-family: Inter-Medium;
`;
const ScoreBoard = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid #e2e2e2;
    border-radius: 16px;
    padding: 20px;
    align-items: center;
    margin-top: 20px;
`;
const ExamScore = styled.h1`
    color: #333333;
    font-size: 18px;
    font-family: Inter-Medium;
`;
const ExamMark = styled.h1`
    font-size: 25px;
    color: #333333;
`;
const PersonalInformationSection = styled.div``;
const Heading = styled.h1`
    color: #333333;
    font-size: 18px;
    font-family: Inter-Medium;
    margin-top: 30px;
`;
const Information = styled.div`
    border: 1px solid #e2e2e2;
    border-radius: 16px;
    padding: 20px;
    margin-top: 20px;
`;
const PersonalData = styled.div`
    margin-bottom: 15px;
    :last-child {
        margin: 0;
    }
`;
const SmallHeading = styled.h3`
    color: #969696;
    font-size: 12px;
`;
const Content = styled.h2`
    text-transform: capitalize;
    background: #fafafa;
    border-radius: 7px;
    padding: 8px;
    color: #333333;
    font-size: 14px;
    font-family: Inter-Medium;
`;
const LoaderDiv = styled.div`
    font-family: Inter-Regular;
    width: 100%;
    height: calc(100vh - 88px);
    display: flex;
    align-items: center;
    justify-content: center;
`;
