import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { COLORS, textStyle } from "../../utils/constants";
import { Context } from "../../context/Store";
import UseOutsideClick from "../UseOutsideClick";
import Avatar from "react-avatar";

const Header = ({ openMenu }) => {
    const { state } = useContext(Context);
    const [logoutModal, setLogoutModal] = useState(false);
    const userName = state.user_data.user_name;
    const profile = state.user_data?.photo;
    const role = state.user_data?.role;

    const handleOutsideClick = () => {
        setLogoutModal(false);
    };

    return (
        <Container className={openMenu ? "open-menu" : "shrink"}>
            <Heading>
                {role?.toUpperCase()?.replace(/[-_]/g, " ")} Dashboard
            </Heading>
            <View>
                {/* <SearchNav>
                    <img
                        src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/search.svg'
                    />
                </SearchNav>
                <NotificationNav>
                    <img
                        src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/notification.svg'
                    />
                </NotificationNav> */}
                <AvatarDiv
                    onClick={(e) => {
                        e.preventDefault();
                        setLogoutModal(!logoutModal);
                    }}
                >
                    <AvatarContainer>
                        {profile ? (
                            <ImageBox>
                                <img src={profile} alt="Icon" />
                            </ImageBox>
                        ) : (
                            <AvatarBox>
                                <Avatar
                                    name={userName}
                                    round={true}
                                    size="40"
                                    textSizeRatio={0.5}
                                />
                            </AvatarBox>
                        )}
                    </AvatarContainer>
                    <AvatarName>{userName.replace(/[-_]/g, " ")}</AvatarName>
                    <DownArrow>
                        <img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/down.svg" />
                        <UseOutsideClick onClickOutside={handleOutsideClick}>
                            <LogoutDiv
                                className={logoutModal && "active"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    localStorage.clear();
                                    window.location = "/";
                                }}
                            >
                                Logout
                            </LogoutDiv>
                        </UseOutsideClick>
                    </DownArrow>
                </AvatarDiv>
            </View>
        </Container>
    );
};
const Container = styled.section`
    border-bottom: 1px solid #e2e2e2;
    z-index: 6;
    &.open-menu {
        padding: 0 30px 0 290px;
        @media all and (max-width: 1500px) {
            padding: 0 30px 0 270px;
        }
        @media all and (max-width: 680px) {
            padding: 0 20px 0 270px;
        }
        @media all and (max-width: 360px) {
            padding: 0 20px 0 80px;
        }
    }
    &.shrink {
        padding: 0 30px 0 90px;
        @media all and (max-width: 680px) {
            padding: 0 20px 0 80px;
        }
    }
    background: ${COLORS.light.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    @media all and (max-width: 640px) {
        justify-content: flex-end;
    }
`;
const Heading = styled.p`
    ${textStyle.title14}
    text-transform: uppercase;
    margin: 0px;
    @media all and (max-width: 640px) {
        display: none;
    }
`;
const View = styled.div`
    display: flex;
    flex-direction: row;
`;
const SearchNav = styled.div`
    width: 35px;
    height: 35px;
    padding: 8px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: ${COLORS.light[300]};
    }
    background: ${COLORS.light[200]};
    margin-right: 15px;
    img {
        display: block;
        width: 100%;
    }
`;

const NotificationNav = styled.div`
    width: 35px;
    height: 35px;
    /* padding: 8px; */
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: ${COLORS.light[300]};
    }
    background: ${COLORS.light[200]};
    margin-right: 16px;
    img {
    }
`;
const Notification = styled.img``;
const AvatarContainer = styled.div`
    height: 100%;
    margin-right: 14px;
    display: flex;
    align-items: center;
`;
const ImageBox = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        display: block;
    }
`;

const AvatarBox = styled.div`
    & div.sb-avatar__text {
        div {
            font-size: 18px !important;
        }
    }
`;

const AvatarDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;
const AvatarName = styled.p`
    text-transform: capitalize;
    @media all and (max-width: 768px) {
        display: none;
    }

    ${textStyle.body16}
    padding:0px;
    margin: 0;
    margin-right: 14px;
`;
const DownArrow = styled.span`
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 20px;
    img {
        width: 100%;
        display: block;
    }
`;
const LogoutDiv = styled.div`
    position: absolute;
    top: 15px;
    right: -15px;
    height: 40px;
    width: 150px;
    z-index: 6;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    transition: 150ms ease-in all;
    opacity: 0;
    visibility: hidden;
    box-shadow: 3px 10px 13px 0px #0000001f;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    font-family: Inter-Medium;
    &.active {
        top: 50px;
        opacity: 1;
        visibility: visible;
    }
    @media all and (max-width: 680px) {
        right: 0;
    }
    @media all and (max-width: 640px) {
        width: 110px;
        font-size: 14px;
    }
`;
export default Header;
