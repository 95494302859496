
import React, { useState } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

function DateRangeModal({
    modal,
    setModal,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onSubmit
}) {
    const today = new window.Date();
    const [selectedDateMode, setDateMode] = useState("");
    return (
        <MainContainer className={modal && "show"}>
            <Container>
                <TopDiv>
                    <Title>Date Range</Title>
                    <CloseButton
                        onClick={() => {
                            setModal((prev) => !prev);
                        }}
                    >
                        <img
                            src={require("../../../assets/icons/pros/close-circle.svg").default}
                            alt="Icon"
                        />
                    </CloseButton>
                </TopDiv>
                <MiddleContainer>
                    <From
                        onClick={() => {
                            setDateMode("start_date");
                        }}
                    >
                        <SubHead>From</SubHead>
                        <FromDateContainer>
                            {startDate
                                ? moment(startDate).format("DD-MM-YYYY")
                                : "DD - MM - YY"}
                        </FromDateContainer>
                        <Date
                            className={
                                selectedDateMode === "start_date" && "active"
                            }
                        >
                            <Calendar
                                onChange={(value) => {
                                    setStartDate(value);
                                    setDateMode("end_date");
                                }}
                                value={startDate}
                                maxDate={today}
                            />
                        </Date>
                    </From>
                    <ToContainer
                        onClick={() => {
                            setDateMode("end_date");
                        }}
                    >
                        <SubHead>To</SubHead>
                        <FromDateContainer>
                            {endDate
                                ? moment(endDate).format("DD-MM-YYYY")
                                : "DD - MM - YY"}
                        </FromDateContainer>
                        <Date
                            className={
                                selectedDateMode === "end_date" && "active"
                            }
                        >
                            <Calendar
                                onChange={(value) => {
                                    setEndDate(value);
                                    setDateMode("");
                                }}
                                value={endDate}
                                maxDate={today}
                            />
                        </Date>
                    </ToContainer>
                </MiddleContainer>
                <BottomContainer>
                    <SubmitButton onClick={onSubmit}>
                        Submit
                    </SubmitButton>
                </BottomContainer>
            </Container>
        </MainContainer>
    );
}

export default DateRangeModal;
const MainContainer = styled.div`
    transition: 200ms ease all;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(0);
    background-color: rgba(211, 211, 211, 0.3);
    backdrop-filter: blur(2px);
    z-index: 9999;
    overflow-y: hidden;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    &.show {
        transform: scale(1);
    }
`;
const Container = styled.div`
    width: 750px;
    background-color: #fff;
    padding: 40px;
    max-height: 90vh;
    overflow-y: visible;
    border-radius: 8px;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1180px) {
        width: 670px;
    }
    @media all and (max-width: 980px) {
        width: 600px;
        /* overflow-y: scroll; */
    }
    @media all and (max-width: 768px) {
        width: 560px;
    }
    @media all and (max-width: 640px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 95%;
    }
`;
const CloseButton = styled.span`
    display: inline-block;
    width: 30px;
    img {
        width: 100%;
        display: block;
    }
    cursor: pointer;
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px solid #8f8f8f;
`;
const Title = styled.p`
    font-size: 22px;
`;

const MiddleContainer = styled.div`
    padding: 20px 0 0px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    p {
        color: #fff;
        font-family: "gordita_regular";
        font-size: 16px;
    }
    @media all and (max-width: 640px) {
        flex-direction: column;
    }
`;
const From = styled.div`
    width: 48%;
    position: relative;

    @media all and (max-width: 640px) {
        width: 100%;
        margin-bottom: 15px;
    }
`;
const SubHead = styled.div`
    color: #333;
`;
const FromDateContainer = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border: 2px solid #0fa76f;
    border-radius: 7px;
`;
const Date = styled.div`
z-index: 1;
background-color: #fff;
    position: absolute;
    top: 45px;
    left: 0;
    text-align: center;
    margin-top: 10px;
    opacity: 0;
    visibility: hidden;
    transition: 150ms ease all;
    &.active {
       opacity: 1;
       visibility: visible;
       top: 60px;
    }
`;
const ToContainer = styled.div`
    position: relative;
    width: 48%;
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;

const BottomContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 15px;
`
const SubmitButton = styled.div`
    width: 48%;
    cursor: pointer;
    height: 50px;
    background-color: #0FA76F;
    border: 1px solid #0FA76F;
    border-radius: 8px;
    color: #fff;
    font-size: 20px;
    font-family: Inter-Medium;
    display: flex;
    align-items: center;
    justify-content: center;
`


