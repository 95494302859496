import React from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/constants";

function AllWinnersCard({ winnersDetails }) {
    const exam_status = "not-completed";
    return (
        <MainContainer>
            {exam_status === "completed" ? (
                <>
                    {winnersDetails.map((winner, index) => (
                        <Container key={index}>
                            <BgImg>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-03-2023/winnerposter.png"
                                    alt="Poster"
                                />
                            </BgImg>
                            <DotsDiv href="#" download target="_blank">
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/download-white.svg"
                                    alt="Dots"
                                />
                            </DotsDiv>
                            <StudentPhoto>
                                <img src={winner.image} alt="Profile" />
                            </StudentPhoto>
                            <DetailDiv>
                                <StudentName>{winner.name}</StudentName>
                                <WardName>{winner.ward}</WardName>
                            </DetailDiv>
                        </Container>
                    ))}
                </>
            ) : (
                <ExamContainer>
                    <Center>
                        <Badge>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/first-place-banner.svg"
                                alt="Badge"
                            />
                        </Badge>
                        <Text>The winner will be announced on</Text>
                        <Date>5, May 2023</Date>
                    </Center>
                </ExamContainer>
            )}
        </MainContainer>
    );
}

export default AllWinnersCard;


const ExamContainer = styled.div`
    width: 100%;
    padding: 70px 0;
    border-radius: 16px;
    border: 2px solid #0fa76f;
    background-color: #fff;
`;
const Badge = styled.span`
    display: block;
    width: 130px;
    img {
        display: block;
        width: 100%;
    }
`;
const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Text = styled.p`
    color: #969696;
    font-family: "Inter-Regular";
    font-size: 15px;
    margin: 20px 0 6px 0;
`;
const Date = styled.p`
    font-family: "Inter-Medium";
    font-size: 20px;
    color: #0fa76f;
`;


const MainContainer = styled.div`
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    overflow-x: scroll;
    width: 100%;
    max-width: 30vw;
    grid-gap: 10px;
    &::-webkit-scrollbar-thumb {
        background-color: ${COLORS.steyp.primary};
        border-radius: 5px;
    }
    &::-webkit-scrollbar {
        height: 3px;
        width: 3px;
    }
    @media all and (max-width: 980px) {
        max-width: 83vw;
    }
    @media all and (max-width: 640px) {
        max-width: 90vw;
    }
`;
const Container = styled.div`
    position: relative;
    max-width: 275px;
    min-width: 275px;
    width: 100%;
    /* min-width: 400px; */
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;
const BgImg = styled.div`
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        display: block;
    }
`;
const DotsDiv = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    cursor: pointer;
    img {
        width: 90%;
        display: block;
    }
`;
const StudentPhoto = styled.span`
    display: inline-block;
    position: absolute;
    top: 32%;
    width: 24%;
    img {
        width: 100%;
        display: block;
    }
`;
const DetailDiv = styled.div`
    position: absolute;
    bottom: 13px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const StudentName = styled.h4`
    margin-bottom: 7px;
    font-size: 14px;
`;
const WardName = styled.span`
    text-transform: uppercase;
    padding: 5px 7px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.12);
    border: 1px solid #fff;
    backdrop-filter: blur(3.82);
    border-radius: 16px;
    font-size: 10px;
`;
