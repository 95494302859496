import React from "react";
import styled from "styled-components";

function OutLineButton({ icon, content }) {
    return (
        <Button icon={icon} content={content} >
            {icon && (
                <Whatsapp content={content} >
                    <img src={icon} alt={content} />
                </Whatsapp>
            )}
            {content && <Text>{content}</Text>}
        </Button>
    );
}

export default OutLineButton;

const Button = styled.span`
    color: #0fa76f;
    padding: ${({ icon, content }) => (icon && content ? "6px 10px" : "4px")};
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    background-color: white;
    cursor: pointer;
    border: 2px solid #0fa76f;
    border-radius: 8px;
`;
const Whatsapp = styled.span`
    display: inline-block;
    margin-right: ${({ content }) => (content ? "10px" : "")};
    img {
        display: block;
        width: 100%;
    }
`;
const Text = styled.span``;
