import React, { useContext, useEffect, useState } from "react";
import {
	Route,
	BrowserRouter as Router,
	Routes,
	Navigate,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Context } from "../../context/Store";
import AppRouter from "./AppRouter";
import AuthRoute from "../routes/AuthRoute";
import AuthRouter from "./authentication/AuthRouter";
import PrivateRoute from "../routes/PrivateRoute";

const MainRouter = () => {
	const { state, dispatch } = useContext(Context);

	const [searchParams] = useSearchParams();
	const currentParams = Object.fromEntries([...searchParams]);

	useEffect(() => {
		async function fetchUserData() {
			let promise = new Promise((resolve, reject) => {
				let user_data = localStorage.getItem("user_data");
				user_data = JSON.parse(user_data);

				dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });

				setTimeout(() => {
					resolve("done!");
				}, 500);
			});

			let result = await promise;
		}

		fetchUserData();
	}, []);

	useEffect(() => {
		dispatch({
			type: "UPDATE_SEARCH_PARAMS",
			payload: currentParams,
		});
	}, []);

	return (
		<Routes>
			<Route
				path="auth/*"
				element={
					<AuthRoute>
						<AuthRouter />
					</AuthRoute>
				}
			/>

			<Route
				path="/*"
				element={
					<PrivateRoute>
						<AppRouter />
					</PrivateRoute>
				}
			/>
		</Routes>
	);
};
export default MainRouter;
