// First we need to import axios.js
import axios from "axios";

// #### LIVE SERVER CONFIGS #### //

export const axiosConfig = axios.create({
	baseURL: "https://api-scholarship.steyp.com/api/v1/",
});

// #### DEVELOPER SERVER CONFIGS #### //

// export const axiosConfig = axios.create({
//     baseURL: "https://developers-steyp-scholarship.talrop.works/api/v1/",
// });

// #### DEVELOPER LOCAL SERVER CONFIGS #### //
// export const axiosConfig = axios.create({
//     baseURL: "http://127.0.0.1:8000/api/v1/",
// });