import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { COLORS, textStyle } from "../../utils/constants";
import {
    NavLink,
    Link,
    useLocation,
    Navigate,
    useNavigate,
} from "react-router-dom";
import { motion } from "framer-motion";
import { Context } from "../../context/Store";

const DrawerMenu = ({
    setOpenMenu,
    openMenu,
    itemChange,
    setItemActive,
    Itemactive,
    active,
    setActive,
    activeTeam,
}) => {
    const {
        state: {
            user_data: { role },
        },
    } = useContext(Context);

    const [activeItem, setActiveItem] = useState(null);
    const [isShrink, setShrink] = useState(true);
    const [maxWidth, setMaxWidth] = useState(false);
    const [minWidth, setMinWidth] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const headSubMenu = [
        {
            id: 1,
            name: "Program Assistants",
            to: "/team/program-assistants",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramAssistants.svg",
        },
        {
            id: 2,
            name: "Program Associates",
            to: "/team/program-associates",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramAssociates.svg",
        },
        {
            id: 3,
            name: "Program Executives",
            to: "/team/program-executives",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramExecutives.svg",
        },
        {
            id: 4,
            name: "Program Managers",
            to: "/team/program-managers",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramManagers.svg",
        },
    ];

    const ProgramManagerSubMenu = [
        {
            id: 1,
            name: "Program Assistants",
            to: "/team/program-assistants",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramAssistants.svg",
        },
        {
            id: 2,
            name: "Program Associates",
            to: "/team/program-associates",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramAssociates.svg",
        },
        {
            id: 3,
            name: "Program Executives",
            to: "/team/program-executives",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramExecutives.svg",
        },
    ];
    const ProgramExecutiveSubMenu = [
        {
            id: 1,
            name: "Program Assistants",
            to: "/team/program-assistants",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramAssistants.svg",
        },
        {
            id: 2,
            name: "Program Associates",
            to: "/team/program-associates",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramAssociates.svg",
        },
    ];
    const ProgramAssociateSubMenu = [
        {
            id: 1,
            name: "Program Assistants",
            to: "/team/program-assistants",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/ProgramAssistants.svg",
        },
    ];
    const BDASideMenu = [
        {
            id: 1,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/applications-icon-new.svg",
            name: "Applications",
            to: "/applications",
            subMenu: [],
        },
        {
            id: 2,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/location-icon.svg",
            name: "Locations",
            to: "locations/",
            subMenu: [
                {
                    id: 1,
                    name: "Districts",
                    to: "locations/districts/",
                    icon: require("../../../assets/icons/pros/Districts.svg")
                        .default,
                },
                {
                    id: 2,
                    name: "Corporations",
                    to: "locations/corporations/",
                    icon: require("../../../assets/icons/pros/Corporations.svg")
                        .default,
                },
                {
                    id: 3,
                    name: "Municipalities",
                    to: "locations/municipalities/",
                    icon: require("../../../assets/icons/pros/Municipalities.svg")
                        .default,
                },
                {
                    id: 4,
                    name: "Panchayats",
                    to: "locations/panchayats/",
                    icon: require("../../../assets/icons/pros/Panchayats.svg")
                        .default,
                },
                {
                    id: 5,
                    name: "Wards",
                    to: "locations/wards/",
                    icon: require("../../../assets/icons/pros/Wards.svg")
                        .default,
                },
            ],
        },
    ];

    const [menuData, setMenuData] = useState([
        {
            id: 1,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/dashboard-icon.svg",
            name: "Dashboard",
            to: "/dashboard",
            subMenu: [],
        },
        {
            id: 2,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/winners-icon.svg",
            name: "Winners",
            to: "/winners",
            subMenu: [],
        },
        {
            id: 3,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/participants-icon-new.svg",
            name: "Participants",
            to: "/participants",
            subMenu: [],
        },
        {
            id: 4,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/applications-icon-new.svg",
            name: "Applications",
            to: "/applications",
            subMenu: [],
        },
        {
            id: 5,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/team-icon.svg",
            name: "Team",
            to: "team/",
            subMenu:
                role === "program_head"
                    ? headSubMenu
                    : role === "program_manager"
                    ? ProgramManagerSubMenu
                    : role === "program_executive"
                    ? ProgramExecutiveSubMenu
                    : role === "program_associate"
                    ? ProgramAssociateSubMenu
                    : [],
        },
        {
            id: 6,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/location-icon.svg",
            name: "Locations",
            to: "locations/",
            subMenu: [
                {
                    id: 1,
                    name: "Districts",
                    to: "locations/districts/",
                    icon: require("../../../assets/icons/pros/Districts.svg")
                        .default,
                },
                {
                    id: 2,
                    name: "Corporations",
                    to: "locations/corporations/",
                    icon: require("../../../assets/icons/pros/Corporations.svg")
                        .default,
                },
                {
                    id: 3,
                    name: "Municipalities",
                    to: "locations/municipalities/",
                    icon: require("../../../assets/icons/pros/Municipalities.svg")
                        .default,
                },
                {
                    id: 4,
                    name: "Panchayats",
                    to: "locations/panchayats/",
                    icon: require("../../../assets/icons/pros/Panchayats.svg")
                        .default,
                },
                {
                    id: 5,
                    name: "Wards",
                    to: "locations/wards/",
                    icon: require("../../../assets/icons/pros/Wards.svg")
                        .default,
                },
            ],
        },
        {
            id: 7,
            icon: require("../../../assets/icons/question-icon.svg").default,
            name: "Questions",
            to: "questions/?subject=mathematics&category=up",
            subMenu: [],
        },
    ]);
    const MsfSideMenu = [
        {
            id: 1,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/dashboard-icon.svg",
            name: "Dashboard",
            to: "/dashboard",
            subMenu: [],
        },
        {
            id: 2,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/winners-icon.svg",
            name: "Winners",
            to: "/winners",
            subMenu: [],
        },
        {
            id: 3,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/participants-icon-new.svg",
            name: "Participants",
            to: "/participants",
            subMenu: [],
        },
        {
            id: 4,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/applications-icon-new.svg",
            name: "Applications",
            to: "/applications",
            subMenu: [],
        },
    ]
    const GreenovationMenu =[
        {
            id: 1,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/dashboard-icon.svg",
            name: "Dashboard",
            to: "/dashboard",
            subMenu: [],
        },
        {
            id: 2,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/winners-icon.svg",
            name: "Winners",
            to: "/winners",
            subMenu: [],
        },
        {
            id: 3,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/participants-icon-new.svg",
            name: "Participants",
            to: "/participants",
            subMenu: [],
        },
        {
            id: 4,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/applications-icon-new.svg",
            name: "Applications",
            to: "/applications",
            subMenu: [],
        },
    ]

    const variants = {
        open: { opacity: 1, y: "0%", height: "auto", width: "100%" },
        closed: { opacity: 0, y: "-10%", height: 0, width: "100%" },
    };
    //  Preventing back scroll while a modal is active
    useEffect(() => {
        const box = document.getElementById("main");
        if (isShrink) {
            box?.classList.add("active");
        } else {
            box?.classList.remove("active");
        }
        !isShrink && window.innerWidth <=1500
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "unset");
    }, [isShrink]);

    // Handling side menu using window size 
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 1500) {
                setShrink(true);
                setMaxWidth(true);
                setOpenMenu(false);
                setMinWidth(false);
            } else {
                setShrink(false);
                setOpenMenu(true);
                setMaxWidth(false);
                setMinWidth(false);
            }
            if (window.innerWidth < 640) {
                setMaxWidth(false);
                setMinWidth(true);
            }
        }
        if (window.innerWidth <= 1500) {
            setShrink(true);
            setMaxWidth(true);
            setOpenMenu(false);
            setMinWidth(false);
        } else {
            setShrink(false);
            setMaxWidth(false);
            setMinWidth(false);
            setOpenMenu(true);
        }
        if (window.innerWidth < 640) {
            setMaxWidth(false);
            setMinWidth(true);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    
   

    const renderMenus = (menu) => (
        <ListContainer>
            {menu.map((item, index) => {
                return (
                    <div key={item.id}>
                        {item.subMenu.length > 0 ? (
                            <ListItemDiv
                                key={index}
                                onClick={() => {
                                    setActiveItem(item);
                                    !location.pathname.includes(item.to) &&
                                        navigate(item.to);
                                }}
                            >
                                <ItemContainer
                                    className={
                                        location.pathname.includes(item.to) &&
                                        "active"
                                    }
                                >
                                    <IconView>
                                        <Icon>
                                            <img src={item.icon} alt="Logo" />
                                        </Icon>
                                        <IconText>{item.name}</IconText>
                                    </IconView>

                                    <motion.div
                                        animate={{
                                            x: 0,
                                            y: 0,
                                            scale: 1,
                                            rotate:
                                                item.id === activeItem?.id
                                                    ? -90
                                                    : 0,
                                        }}
                                        style={{
                                            height: "max-content",
                                        }}
                                    >
                                        <Arrow
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                                            alt="Logo"
                                            style={{
                                                display: openMenu
                                                    ? "flex"
                                                    : "none",
                                            }}
                                        />
                                    </motion.div>
                                </ItemContainer>
                                <motion.div
                                    animate={
                                        item.id === activeItem?.id
                                            ? "open"
                                            : "closed"
                                    }
                                    variants={variants}
                                >
                                    <TeamList
                                        className={
                                            item.id === activeItem?.id
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        {item.subMenu.length > 0 &&
                                            item.subMenu.map((item, index) => {
                                                return (
                                                    <TeamListItem
                                                        isShrink={isShrink}
                                                        key={index}
                                                        to={item.to}
                                                    >
                                                        <SubmenuDiv>
                                                            <SubIcon>
                                                                <img
                                                                    src={
                                                                        item.icon
                                                                    }
                                                                    alt="Logo"
                                                                />
                                                            </SubIcon>
                                                            <ItemText>
                                                                {item.name}
                                                            </ItemText>
                                                        </SubmenuDiv>
                                                    </TeamListItem>
                                                );
                                            })}
                                    </TeamList>
                                </motion.div>
                            </ListItemDiv>
                        ) : (
                            <ListItemLink
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(item.to);
                                    setActiveItem(null);
                                }}
                                to={item.to}
                                className={`${role}${" "}${item.name}`}
                            >
                                <IconView>
                                    <Icon>
                                        <img src={item.icon} alt="Logo" />
                                    </Icon>
                                    <IconText
                                        style={{
                                            opacity:
                                                !openMenu && itemChange
                                                    ? "0"
                                                    : "1",
                                        }}
                                    >
                                        {item.name}
                                    </IconText>
                                </IconView>
                            </ListItemLink>
                        )}
                    </div>
                );
            })}
        </ListContainer>
    );

    return (
        <Container
            className={isShrink ? "shrink" : ""}
            onMouseEnter={() => {
                maxWidth && setShrink(false);
                maxWidth && setOpenMenu(true);
            }}
            onMouseLeave={() => {
                (maxWidth || minWidth) && setShrink(true);
                (maxWidth || minWidth) && setOpenMenu(false);
            }}
        >
            <View>
                <Header className={isShrink && "active"}>
                    {isShrink ? (
                        <LogoView to="/" className={isShrink && "active"}>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/Logo-E.svg"
                                alt="Logo"
                            />
                        </LogoView>
                    ) : (
                        <LogoView2 to="/" className={isShrink && "active"}>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/logo.svg"
                                alt="Logo"
                            />
                        </LogoView2>
                    )}
                    <MenuButton onClick={() => setShrink((prev) => !prev)}>
                        <MenuIcon
                            src={
                                isShrink
                                    ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/menu-2.svg"
                                    : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/menu.svg"
                            }
                            alt="Logo"
                        />
                    </MenuButton>
                </Header>
                <MenuItems>
                    {role === 'msf_program_head' ? (
                        <>{renderMenus(MsfSideMenu)}</>
                    ):
                    role === "business_development_associate" ? (
                        <>{renderMenus(BDASideMenu)}</>
                    ) : role === "green_innovation_scholarship_head" ? (
                        <>{renderMenus(GreenovationMenu)}</>
                    ): (
                        <>{renderMenus(menuData)}</>
                    )} 
                </MenuItems>
            </View>

            <TalropLogoContainer>
                <TalropLogo
                    src={
                        isShrink
                            ? 'https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/12-04-2023/talrop-logo-2.svg'
                            : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/talrop-logo.svg"
                    }
                    alt="Logo"
                />
            </TalropLogoContainer>
        </Container>
    );
};

const Header = styled.div`
    position: fixed;
    top: 0;
    padding: 19px 16px 5px 16px;
    left: 0;
    background: #fff;
    z-index: 2;
    width: 258px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 100ms all;
    &.active {
        width: 73px;
    }
    @media all and (max-width: 640px) {
        padding: 12px 16px 8px 16px;
        &.active {
            width: 105px;
        }
    }
    @media all and (max-width: 1280) {
    }
`;
const LogoView = styled(Link)`
    display: inline-block;
    height: 30px;
    transition: 100ms;
    margin-right: 15px;
    img {
        height: 100%;
        display: block;
    }
    @media all and (max-width: 480px) {
        display: block;
    }
`;
const LogoView2 = styled(Link)`
    overflow-x: hidden;
    width: 100px;
    margin-right: 15px;
    height: auto;
    img {
        display: block;
        width: 100%;
    }
`;
const MenuButton = styled.span`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid ${COLORS.light[200]};
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${COLORS.light.white};
    z-index: 999;
    &.open-menu {
        right: 0px;
    }
    &.shrink {
        right: -30px;
        @media all and (max-width: 480px) {
            top: -5px;
        }
        @media all and (max-width: 440px) {
            right: -25px;
        }
        @media all and (max-width: 350px) {
            right: -20px;
        }
    }
    @media all and (max-width: 640px) {
        display: flex;
    }
`;

const MenuIcon = styled.img`
    width: 18px;
    display: block;
`;
const MenuItems = styled.div`
    overflow: hidden;
    padding-top: 60px;
`;
const View = styled.div`
    padding: 15px;
    overflow-y: scroll;
    min-height: calc(100vh - 65px);
    height: 100%;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ListContainer = styled.div`
    list-style: none;
`;
const ListItemLink = styled(NavLink)`
    cursor: pointer;
    border-radius: 9px;
    display: flex;
    align-items: center;
    padding: 10px 7px;
    margin-bottom: 10px;
    border-left: 3px solid ${COLORS.light.white};

    &.active {
        background: ${COLORS.talrop.background};
        border-left: 3px solid ${COLORS.steyp.primary};
    }
    &.location {
        justify-content: space-between;
    }
    &.program_assistant.Team {
        display: none;
    }
    &.Questions {
        display: none;
    }
    &.program_head.Questions {
        display: flex;
    }
    justify-content: space-between;
`;

const ListItemDiv = styled.div`
    cursor: pointer;

    padding-bottom: 0px;
    border-left: 3px solid ${COLORS.light.white};
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.location {
        justify-content: space-between;
    }
    justify-content: space-between;
`;
const Icon = styled.span`
    margin-right: 10px;
    width: 24px;
    height: 24px;
    display: flex;
    img {
        width: 100%;
        display: block;
    }
`;
const SubIcon = styled.span`
    margin-right: 13px;
    width: 17px;
    height: 17px;
    display: flex;
    img {
        width: 100%;
        display: block;
    }
`;
const IconText = styled.p`
    padding: 0px;
    margin: 0px;
    font-family: "Inter-Regular";
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    opacity: 1 !important;
`;
const Arrow = styled.img``;
const IconView = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    &.shrink {
        margin: 0 auto;
    }
`;
const TeamList = styled.div`
    flex-direction: column;
    width: 100%;
    opacity: 0;
    height: auto;
    display: none;
    &.active {
        opacity: 1;
        height: auto;
        display: block;
    }
`;
const TeamListItem = styled(NavLink)`
    cursor: pointer;
    border-radius: 9px;
    align-items: center;
    padding: ${({isShrink}) => isShrink ? '10px' : '10px 10px 10px 6px'};
    transition: all ease 150ms;
    display: flex;
    justify-content: start;
    border-left: 3px solid ${COLORS.light.white};

    &.active {
        background: ${COLORS.talrop.background};
        border-left: 3px solid ${COLORS.steyp.primary};
    }
    &.location {
        justify-content: space-between;
    }
`;
const ItemText = styled.p`
    ${textStyle.body16}
    padding:0px;
    margin: 0px;
    white-space: nowrap;
`;
const SubmenuDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 226px;
    width: 100%;
`;
const TalropLogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLORS.steyp.background};
    border-top: 1px solid ${COLORS.steyp.border};
    padding: 10px;
`;
const TalropLogo = styled.img``;
const Container = styled.div`
    background: ${COLORS.light.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    min-height: 100vh;
    height: 100%;
    border-right: 1px solid ${COLORS.light[300]};
    background: ${COLORS.light.white};
    transition: 0.8s;
    transition-timing-function: cubic-bezier(0.18, 0.89, 0.2, 1.21);
    width: 260px;
    overflow: hidden;
    &.shrink {
        width: 73px;
        @media all and (max-width: 640px) {
            width: 0px;
        }
    }
`;
const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 6px;
    padding-right: 10px;
    border-radius: 9px;
    margin-bottom: 10px;
    align-items: center;
    &.active {
        background: ${COLORS.talrop.background};
        border-left: 3px solid ${COLORS.steyp.primary};
    }
`;

export default DrawerMenu;
