import React from "react";
import Lottie from "react-lottie";
import loader from "../../../assets/lotties/page-loader.json";
import styled from "styled-components";

function SectionLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };
    return (
        <LoaderBox>
            <Lottie options={defaultOptions} width={60} height={60} />
        </LoaderBox>
    );
}

export default SectionLoader;
const LoaderBox = styled.div``;
