import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { axiosConfig } from "../../../axiosConfig";
import {
    useLocation,
    Link,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import queryString from "query-string";

import UseOutsideClick from "../UseOutsideClick";
import { Context } from "../../context/Store";
import DropLoader from "../loaders/DropLoader";

function WinnersTableHeader({
    searchWord,
    setSearchWord,
    manager,
    table,
    filterType,
    filter,
    // dateRangeModal,
    setDateRangeModal,
    greenovation,
}) {
    const [districts, setDistricts] = useState([]);
    const [districtDrop, setDistrictDrop] = useState(false);
    const [isDistrictSelected, setDistrictSelected] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const [selectedDistrictName, setSelectedDistrictName] = useState("");

    const [municipalityDrop, setMunicipalityDrop] = useState(false);
    const [muncipality, setMuncipality] = useState([]);
    const [isMuncipalitySelected, setMuncipalitySelected] = useState(false);
    const [selectedMuncipality, setSelectedMuncipality] = useState("");
    const [selectedMuncipalityName, setSelectedMuncipalityName] = useState("");
    const [localSearch, setLocalSearch] = useState("");

    const [wards, setWards] = useState([]);
    const [wardDrop, setWardDrop] = useState(false);
    const [selectedWard, setSelectedWard] = useState("");
    const [selectedWardName, setSelectedWardName] = useState("");
    const [wardSelected, setWardSelected] = useState(false);
    const [wardSearch, setWardSearch] = useState("");

    const [shrinkField, setShrinkField] = useState(true);
    const [isDistrictLoading, setDistrictLoading] = useState(false);
    const [isMunicipalityLoading, setMunicipalityLoading] = useState(false);
    const [isWardLoading, setWardLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [isCategoryLoading, setCategoryLoading] = useState(false);
    const [isClassLoading, setClassLoading] = useState(false);

    const [categories, setCategories] = useState([
        {
            id: 1,
            name: "UP",
            param: "up",
        },
        {
            id: 2,
            name: "HS",
            param: "hs",
        },
        {
            id: 3,
            name: "HSS",
            param: "hss",
        },
    ]);
    // const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedCategoryName, setSelectedCategoryName] = useState("");
    const [categoryDropdown, setCategoryDropdown] = useState(false);

    const [classCategory, setClassCategory] = useState([
        {
            id: 5,
            name: "5th",
            param: "5",
        },
        {
            id: 6,
            name: "6th",
            param: "6",
        },
        {
            id: 7,
            name: "7th",
            param: "7",
        },
        {
            id: 8,
            name: "8th",
            param: "8",
        },

        {
            id: 9,
            name: "9th",
            param: "9",
        },
        {
            id: 10,
            name: "10th",
            param: "10",
        },
        {
            id: 12,
            name: "11th",
            param: "+1",
        },
        {
            id: 11,
            name: "12th",
            param: "+2",
        },
    ]);
    // const [selectedClass, setSelectedClass] = useState("");
    const [selectedClassName, setSelectedClassName] = useState("");
    const [classDropdown, setClassDropdown] = useState(false);
    const dayRange = [
        {
            id: 1,
            name: "Last 7 days",
            days: 7,
        },
        {
            id: 2,
            name: "Last 14 days",
            days: 14,
        },
    ];
    const [dateDropDown, setDateDropDown] = useState(false);
    const [selectedDayRange, setSelectedDayRange] = useState("");

    const location = useLocation();
    const parse = queryString.parse(location.search);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    useEffect(() => {
        if (parse.district_pk) {
            setDistrictSelected(true);
            setSelectedDistrict(parse.district_pk);
        } else {
            setDistrictSelected(false);
            setSelectedDistrict("");
        }
        if (parse.localbody_pk) {
            setMuncipalitySelected(true);
            setSelectedMuncipality(parse.localbody_pk);
        } else {
            setSelectedMuncipality("");
            setMuncipalitySelected(false);
        }
    }, [parse]);

    const districtOutsideClick = () => {
        setDistrictDrop(false);
    };
    const LocalOutsideClick = () => {
        setMunicipalityDrop(false);
    };
    const WardOutsideClick = () => {
        setWardDrop(false);
    };
    const categoryOutsideClick = () => {
        setCategoryDropdown(false);
    };
    const classOutsideClick = () => {
        setClassDropdown(false);
    };
    const DateRangeOutsideClick = () => {
        setDateDropDown(false);
    };

    // <===============   Outside click for Filter Drop   ===============>
    const FilterBoxRef = useRef(null);
    const useFilterOutSideClick = (ref) => {
        useEffect(() => {
            document.addEventListener("mousedown", handleOutSideClick);
            return () => {
                document.removeEventListener("mousedown", handleOutSideClick);
            };
        }, [ref]);
        const handleOutSideClick = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShowFilters(false);
            }
        };
    };
    useFilterOutSideClick(FilterBoxRef);

    // <===================   Listing 14 Districts   ===============>
    useEffect(() => {
        setDistrictLoading(true);
        axiosConfig
            .get("locations/districts/")
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setDistricts(data);
                    setDistrictLoading(false);
                } else {
                    setDistrictLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setDistrictLoading(false);
            });
    }, []);

    // <=====================   Listing all Municipalities   ====================>
    const LocalbodyList = () => {
        setMunicipalityLoading(true);
        axiosConfig
            .get(`locations/localbodies/${selectedDistrict}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    q: localSearch,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setMunicipalityLoading(false);
                    setMuncipality(data);
                } else if (StatusCode === 6001) {
                    setMunicipalityLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setMunicipalityLoading(false);
            });
    };
    // <============  Listing all Wards  ==============>
    const WardsList = () => {
        setWardLoading(true);
        axiosConfig
            .get(`locations/wardcode/${selectedDistrict}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    q: wardSearch,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setWardLoading(false);
                    setWards(data);
                } else if (StatusCode === 6001) {
                    setWardLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setWardLoading(false);
            });
    };

    useEffect(() => {
        isDistrictSelected && LocalbodyList();
    }, [isDistrictSelected, localSearch, selectedDistrict]);

    useEffect(() => {
        isDistrictSelected && isMuncipalitySelected && WardsList();
    }, [isMuncipalitySelected, wardSearch, selectedMuncipality]);

    // <===========  Adding and removing ( q | search word ) ===========>
    useEffect(() => {
        if (searchWord) {
            setSearchParams({
                ...currentParams,
                q: searchWord,
            });
        } else {
            const newObject = { ...currentParams };
            delete newObject["q"];
            setSearchParams(newObject);
        }
    }, [searchWord]);

    // <====== Updating Url Params =========>
    const updateUrlParams = (key, value) => {
        if (key == "district_pk") {
            setSelectedDistrictName(value.name);
            setSelectedDistrict(value.id);
            setSelectedMuncipalityName("");
            setSelectedWardName("");
            setSelectedWard("");

            const newUrl = { ...currentParams, district_pk: value.id };
            delete newUrl["localbody_pk"];
            delete newUrl["ward_pk"];
            setSearchParams(newUrl);
        } else if (key == "localbody_pk") {
            setSelectedWardName("");
            setSelectedWard("");
            setSelectedMuncipalityName(value.name);

            const newUrl = { ...currentParams, localbody_pk: value.id };
            delete newUrl["ward_pk"];
            setSearchParams(newUrl);
        }
        if (key === "category") {
            const newUrl = { ...currentParams, student_type: value.param };
            delete newUrl["student_class"];
            setSearchParams(newUrl);
        }
        if (key === "class") {
            const newUrl = { ...currentParams, student_class: value.param };
            delete newUrl["student_type"];
            setSearchParams(newUrl);
        }
        if (key === "day") {
            const newUrl = { ...currentParams, days: value.days };
            delete newUrl["start_date"];
            delete newUrl["end_date"];
            setSearchParams(newUrl);
        }
    };
    // <====== Clearing Url Params =========>
    const removeUrlParams = (key, value) => {
        if (key === "category") {
            const newUrl = { ...currentParams, student_type: value };
            delete newUrl["student_type"];
            setSelectedCategoryName("");
            setSearchParams(newUrl);
        } else if (key === "class") {
            const newUrl = { ...currentParams, student_class: value };
            delete newUrl["student_class"];
            setSelectedClassName("");
            setSearchParams(newUrl);
        } else if (key === "district") {
            setSelectedDistrictName("");
            setSelectedDistrict("");
            setSelectedMuncipalityName("");
            setSelectedWardName("");
            setSelectedWard("");

            const newUrl = { ...currentParams, district_pk: value };
            delete newUrl["district_pk"];
            delete newUrl["localbody_pk"];
            delete newUrl["ward_pk"];
            setSearchParams(newUrl);
        } else if (key === "localbody") {
            setSelectedWardName("");
            setSelectedWard("");
            setSelectedMuncipalityName("");

            const newUrl = { ...currentParams, localbody_pk: value };
            delete newUrl["localbody_pk"];
            delete newUrl["ward_pk"];
            setSearchParams(newUrl);
        } else if (key === "ward") {
            setSelectedWardName("");
            setSelectedWard("");

            const newUrl = { ...currentParams, ward_pk: value };
            delete newUrl["ward_pk"];
            setSearchParams(newUrl);
        } else if (key === "day") {
            setSelectedDayRange("");
            const newUrl = { ...currentParams, days: "" };
            delete newUrl["days"];
            setSearchParams(newUrl);
        }
    };

    return (
        <SectionDiv table={table}>
            <SearchDiv table={table}>
                <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                        setSearchWord(e.target.value);
                    }}
                    value={searchWord}
                />
                <SearchIcon
                    onClick={() => {
                        setShrinkField(!shrinkField);
                    }}
                >
                    <img
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/search-button.svg"
                        alt="Search"
                    />
                </SearchIcon>
            </SearchDiv>
            {manager !== "program_manager" && (
                <FilterContainer>
                    <FiltersDiv ref={FilterBoxRef}>
                        { !greenovation && (<SelectorsDiv
                            filter={filter}
                            className={showFilters && "active"}
                            table={table}
                        >
                            <DropDownSelector table={table}>
                                <UseOutsideClick
                                    onClickOutside={districtOutsideClick}
                                >
                                    <DropDown
                                        table={table}
                                        onClick={() => {
                                            setDistrictDrop(!districtDrop);
                                        }}
                                    >
                                        <p>
                                            {selectedDistrictName
                                                ? selectedDistrictName
                                                : "District"}
                                        </p>
                                        <DropIcon>
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/down.svg"
                                                alt="DownArrow"
                                            />
                                        </DropIcon>
                                    </DropDown>
                                    <DropDownSelectionDiv
                                        className={districtDrop && "active"}
                                    >
                                        {isDistrictLoading ? (
                                            <LoaderBox>
                                                <DropLoader />
                                            </LoaderBox>
                                        ) : districts.length > 0 ? (
                                            <>
                                                <SelectionLists>
                                                    {districts.map(
                                                        (district) => (
                                                            <DistrictName
                                                                className={
                                                                    selectedDistrict ===
                                                                        district.id &&
                                                                    "active"
                                                                }
                                                                key={
                                                                    district.id
                                                                }
                                                                onClick={() => {
                                                                    setDistrictDrop(
                                                                        false
                                                                    );
                                                                    updateUrlParams(
                                                                        "district_pk",
                                                                        district
                                                                    );
                                                                }}
                                                            >
                                                                {district.name}
                                                            </DistrictName>
                                                        )
                                                    )}
                                                </SelectionLists>
                                                <ClearDiv
                                                    onClick={() => {
                                                        setDistrictDrop(false);
                                                        removeUrlParams(
                                                            "district",
                                                            ""
                                                        );
                                                    }}
                                                >
                                                    Clear
                                                </ClearDiv>
                                            </>
                                        ) : (
                                            <NoData>No Data Found!</NoData>
                                        )}
                                    </DropDownSelectionDiv>
                                </UseOutsideClick>
                            </DropDownSelector>
                            {isDistrictSelected &&
                                filterType != "corporation" &&
                                filterType != "panchayat" &&
                                filterType != "municipality" && (
                                    <DropDownSelector table={table}>
                                        <UseOutsideClick
                                            onClickOutside={LocalOutsideClick}
                                        >
                                            <DropDown
                                                table={table}
                                                onClick={() => {
                                                    setMunicipalityDrop(
                                                        !municipalityDrop
                                                    );
                                                }}
                                            >
                                                <p>
                                                    {selectedMuncipalityName
                                                        ? selectedMuncipalityName
                                                        : "Localbody"}
                                                </p>
                                                <DropIcon>
                                                    <img
                                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/down.svg"
                                                        alt="DownArrow"
                                                    />
                                                </DropIcon>
                                            </DropDown>
                                            <DropDownSelectionDiv
                                                className={
                                                    municipalityDrop && "active"
                                                }
                                            >
                                                <LocalBodySearch>
                                                    <input
                                                        type="text"
                                                        value={localSearch}
                                                        placeholder="Search local body"
                                                        onChange={(e) => {
                                                            setLocalSearch(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <SearchIcon>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/search-button.svg"
                                                            alt="Search"
                                                        />
                                                    </SearchIcon>
                                                </LocalBodySearch>
                                                {isMunicipalityLoading ? (
                                                    <LoaderBox>
                                                        <DropLoader />
                                                    </LoaderBox>
                                                ) : muncipality.length > 0 ? (
                                                    <>
                                                        <SelectionLists className="search">
                                                            {muncipality &&
                                                                muncipality.map(
                                                                    (
                                                                        municipality
                                                                    ) => (
                                                                        <DistrictName
                                                                            key={
                                                                                municipality.id
                                                                            }
                                                                            onClick={() => {
                                                                                setMunicipalityDrop(
                                                                                    false
                                                                                );
                                                                                updateUrlParams(
                                                                                    "localbody_pk",
                                                                                    municipality
                                                                                );
                                                                            }}
                                                                        >
                                                                            {
                                                                                municipality.name
                                                                            }
                                                                        </DistrictName>
                                                                    )
                                                                )}
                                                        </SelectionLists>
                                                        <ClearDiv
                                                            onClick={() => {
                                                                setMunicipalityDrop(
                                                                    false
                                                                );
                                                                removeUrlParams(
                                                                    "localbody",
                                                                    ""
                                                                );
                                                            }}
                                                        >
                                                            Clear
                                                        </ClearDiv>
                                                    </>
                                                ) : (
                                                    <NoData type="municipality">
                                                        {localSearch
                                                            ? "No Data Found!"
                                                            : "Enter Your Localbody"}
                                                    </NoData>
                                                )}
                                            </DropDownSelectionDiv>
                                        </UseOutsideClick>
                                    </DropDownSelector>
                                )}
                            {isMuncipalitySelected && (
                                <DropDownSelector table={table}>
                                    <UseOutsideClick
                                        onClickOutside={WardOutsideClick}
                                    >
                                        <DropDown
                                            table={table}
                                            onClick={() => {
                                                setWardDrop(!wardDrop);
                                            }}
                                        >
                                            <p>
                                                {selectedWardName
                                                    ? selectedWardName
                                                    : "Ward"}
                                            </p>
                                            <DropIcon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/down.svg"
                                                    alt="DownArrow"
                                                />
                                            </DropIcon>
                                        </DropDown>
                                        <DropDownSelectionDiv
                                            className={wardDrop && "active"}
                                        >
                                            <LocalBodySearch>
                                                <input
                                                    type="text"
                                                    value={wardSearch}
                                                    placeholder="Search ward"
                                                    onChange={(e) => {
                                                        setWardSearch(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                <SearchIcon>
                                                    <img
                                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/search-button.svg"
                                                        alt="Search"
                                                    />
                                                </SearchIcon>
                                            </LocalBodySearch>
                                            {isWardLoading ? (
                                                <LoaderBox>
                                                    <DropLoader />
                                                </LoaderBox>
                                            ) : wards.length > 0 ? (
                                                <>
                                                    <SelectionLists className="search">
                                                        {wards &&
                                                            wards.map(
                                                                (ward) => (
                                                                    <DistrictName
                                                                        key={
                                                                            ward.id
                                                                        }
                                                                        onClick={() => {
                                                                            setSelectedWard(
                                                                                ward.id
                                                                            );
                                                                            setWardDrop(
                                                                                false
                                                                            );
                                                                            setSelectedWardName(
                                                                                ward.name
                                                                            );
                                                                            setSearchParams(
                                                                                {
                                                                                    ...currentParams,
                                                                                    ward_pk:
                                                                                        ward.id,
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            ward.name
                                                                        }
                                                                    </DistrictName>
                                                                )
                                                            )}
                                                    </SelectionLists>
                                                    <ClearDiv
                                                        onClick={() => {
                                                            setWardDrop(false);
                                                            removeUrlParams(
                                                                "ward",
                                                                ""
                                                            );
                                                        }}
                                                    >
                                                        Clear
                                                    </ClearDiv>
                                                </>
                                            ) : (
                                                <NoData type="ward">
                                                    No Data Found!
                                                </NoData>
                                            )}
                                        </DropDownSelectionDiv>
                                    </UseOutsideClick>
                                </DropDownSelector>
                            )}
                        </SelectorsDiv>)}
                        {(filterType === "applications" ||
                            filterType === "participants" ||
                            filterType === "winners") && (
                            <StudentFilterDiv green={greenovation}>
                                <DropDownSelector>
                                    <UseOutsideClick
                                        onClickOutside={categoryOutsideClick}
                                    >
                                        <DropDown
                                            onClick={() => {
                                                setCategoryDropdown(
                                                    !categoryDropdown
                                                );
                                            }}
                                        >
                                            <p>
                                                {selectedCategoryName
                                                    ? selectedCategoryName
                                                    : "Category"}
                                            </p>
                                            <DropIcon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/down.svg"
                                                    alt="DownArrow"
                                                />
                                            </DropIcon>
                                        </DropDown>
                                        <DropDownSelectionDiv
                                            drop={"category"}
                                            className={
                                                categoryDropdown && "active"
                                            }
                                        >
                                            {isCategoryLoading ? (
                                                <LoaderBox>
                                                    <DropLoader />
                                                </LoaderBox>
                                            ) : categories.length > 0 ? (
                                                <>
                                                    {categories.map(
                                                        (category) => (
                                                            <CategoryName
                                                                className={
                                                                    selectedCategoryName ===
                                                                        category.name &&
                                                                    "active"
                                                                }
                                                                key={
                                                                    category.id
                                                                }
                                                                onClick={() => {
                                                                    setCategoryDropdown(
                                                                        false
                                                                    );
                                                                    setSelectedCategoryName(
                                                                        category.name
                                                                    );
                                                                    updateUrlParams(
                                                                        "category",
                                                                        category
                                                                    );
                                                                }}
                                                            >
                                                                {category.name}
                                                            </CategoryName>
                                                        )
                                                    )}
                                                    <ClearDiv
                                                        onClick={() => {
                                                            setCategoryDropdown(
                                                                false
                                                            );
                                                            selectedCategoryName &&
                                                                removeUrlParams(
                                                                    "category",
                                                                    ""
                                                                );
                                                        }}
                                                    >
                                                        Clear
                                                    </ClearDiv>
                                                </>
                                            ) : (
                                                <NoData>No Data Found!</NoData>
                                            )}
                                        </DropDownSelectionDiv>
                                    </UseOutsideClick>
                                </DropDownSelector>
                                <DropDownSelector>
                                    <UseOutsideClick
                                        onClickOutside={classOutsideClick}
                                    >
                                        <DropDown
                                            style={{
                                                marginRight:
                                                    filterType ===
                                                    "applications"
                                                        ? "10px"
                                                        : "0",
                                            }}
                                            onClick={() => {
                                                setClassDropdown(
                                                    !classDropdown
                                                );
                                            }}
                                        >
                                            <p>
                                                {selectedClassName
                                                    ? selectedClassName
                                                    : "Class"}
                                            </p>
                                            <DropIcon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/down.svg"
                                                    alt="DownArrow"
                                                />
                                            </DropIcon>
                                        </DropDown>
                                        <DropDownSelectionDiv
                                            className={
                                                classDropdown && "active"
                                            }
                                        >
                                            {isClassLoading ? (
                                                <LoaderBox>
                                                    <DropLoader />
                                                </LoaderBox>
                                            ) : classCategory.length > 0 ? (
                                                <>
                                                    <SelectionLists>
                                                        {classCategory.map(
                                                            (standard) => (
                                                                <ClassName
                                                                    className={
                                                                        selectedClassName ===
                                                                            standard.name &&
                                                                        "active"
                                                                    }
                                                                    key={
                                                                        standard.id
                                                                    }
                                                                    onClick={() => {
                                                                        setClassDropdown(
                                                                            false
                                                                        );
                                                                        setSelectedClassName(
                                                                            standard.name
                                                                        );
                                                                        updateUrlParams(
                                                                            "class",
                                                                            standard
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        standard.name
                                                                    }
                                                                </ClassName>
                                                            )
                                                        )}
                                                    </SelectionLists>
                                                    <ClearDiv
                                                        onClick={() => {
                                                            setClassDropdown(
                                                                false
                                                            );
                                                            selectedClassName &&
                                                                removeUrlParams(
                                                                    "class",
                                                                    ""
                                                                );
                                                        }}
                                                    >
                                                        Clear
                                                    </ClearDiv>
                                                </>
                                            ) : (
                                                <NoData>No Data Found!</NoData>
                                            )}
                                        </DropDownSelectionDiv>
                                    </UseOutsideClick>
                                </DropDownSelector>
                            </StudentFilterDiv>
                        )}
                        <FilterDiv
                            table={table}
                            onClick={() => {
                                setShowFilters((prev) => !prev);
                            }}
                        >
                            <DropIcon>
                                <img
                                    src={
                                        require("../../../assets/icons/filter.svg")
                                            .default
                                    }
                                    alt="Filter"
                                />
                            </DropIcon>
                        </FilterDiv>
                    </FiltersDiv>
                </FilterContainer>
            )}
        </SectionDiv>
    );
}

export default WinnersTableHeader;
const StudentFilterDiv = styled.div`
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-left: ${({green}) => green ? "unset": "1px solid #e2e2e2"};
`;
const FilterDiv = styled.div`
    cursor: pointer;
    border: 1px solid #e2e2e2;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    display: none;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 640px) {
        display: ${({ table }) => (table ? "flex" : "none")};
    }
`;
const SelectorsDiv = styled.div`
    display: ${({ filter, table }) =>
        filter ? "none" : table ? "flex" : "flex"};
    align-items: center;
    background-color: #fff;
    transition: 150ms ease all;
    /* border-right: 1px solid #e2e2e2; */
    @media all and (max-width: 640px) {
        box-shadow: ${({ table }) =>
            table && "4px 2px 51px -15px rgba(185,199,188,1)"};
        padding: ${({ table }) => table && "11px"};
        border-radius: ${({ table }) => table && "6px"};
        z-index: 90;
        position: ${({ table }) => table && "absolute"};
        top: ${({ table }) => table && "70px"};
        right: ${({ table }) => table && "-350px"};
        width: ${({ table }) => table && "300px"};
        min-height: ${({ table }) => table && "200px"};
        /* height: 100%; */
        bottom: ${({ table }) => table && "0px"};
        flex-direction: ${({ table }) => table && "column"};
        justify-content: ${({ table }) => table && "flex-start"};
        align-items: ${({ table }) => table && "flex-end"};
        &.active {
            right: 0px;
        }
    }
`;
const SectionDiv = styled.div`
    padding: 10px 16px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media all and (max-width: 980px) {
        flex-direction: ${({ table }) => table && "column"};
        align-items: ${({ table }) => table && "inherit"};
    }
    @media all and (max-width: 640px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;
const LocalBodySearch = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #f4f4f4;
    input {
        flex: 1;
        width: 100%;
        height: 100%;
        border: 1px solid #ddf4d5;
        padding: 5px;
        border-radius: 5px;
    }
`;
const SearchDiv = styled.div`
    height: 42px;
    width: 400px;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    position: relative;
    background-color: #fff;
    z-index: 2;
    transition: 300ms ease all;
    margin-right: 10px;
    input {
        width: 100%;
        height: 100%;
        font-size: 14px;
        padding: 0 35px 0 10px;
        color: #333;
        &::placeholder {
            color: #333;
        }
    }
    @media all and (max-width: 1280px) {
        width: 370px;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 10px;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 0px;
    }
    @media all and (max-width: 768px) {
        width: 320px;
    }

    @media all and (max-width: 480px) {
        width: 280px;
        input {
            width: 100%;
            height: 100%;
            transition: 200ms;
        }
    }
    @media all and (max-width: 360px) {
        width: 280px;
        input {
            width: 100%;
            height: 100%;
        }
    }
`;
const FiltersDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
`;
const FilterContainer = styled.div``;
const SearchIcon = styled.span`
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    img {
        width: 100%;
        display: block;
    }
`;
const DropDownSelector = styled.div`
    position: relative;
    @media all and (max-width: 640px) {
        margin-bottom: ${({ table }) => (table ? "10px" : "0px")};
        width: 100%;
    }
`;
const DropDown = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    padding: 0px 15px;
    height: 42px;
    cursor: pointer;
    font-family: "Inter-Medium";
    margin-right: 10px;
    @media all and (max-width: 640px) {
        justify-content: space-between;
        margin-right: ${({ table }) => (table ? "0px" : "10px")};
    }
`;
const DropIcon = styled.span`
    display: inline-block;
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;

const DropDownSelectionDiv = styled.div`
    position: absolute;
    top: 35px;
    right: -15px;
    height: ${({ drop }) =>
        drop === "category" ? "149px" : drop === "days" ? "161px" : "240px"};
    width: 180px;
    z-index: 6;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    transition: 200ms ease-in all;
    opacity: 0;
    visibility: hidden;
    box-shadow: 3px 10px 13px 0px #0000001f;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    &.active {
        top: 55px;
        opacity: 1;
        visibility: visible;
    }
    @media all and (max-width: 640px) {
        right: 0;
    }
`;

const ClassSelectionDiv = styled.div`
    position: absolute;
    top: 35px;
    right: -15px;
    height: 187px;
    width: 180px;
    z-index: 6;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    transition: 200ms ease-in all;
    opacity: 0;
    visibility: hidden;
    box-shadow: 3px 10px 13px 0px #0000001f;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    &.active {
        top: 55px;
        opacity: 1;
        visibility: visible;
    }
`;
const DistrictName = styled.div`
    display: block;
    cursor: pointer;
    padding: 12px 10px;
    border-bottom: 1px solid #f4f4f4;
    &:hover {
        background-color: #0fa76f;
        color: #fff;
    }
    &.active {
        background-color: #0fa76f;
        color: #fff;
    }
`;
const CategoryName = styled.div`
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #f4f4f4;
    &:hover {
        background-color: #0fa76f;
        color: #fff;
    }
    &.active {
        background-color: #0fa76f;
        color: #fff;
    }
`;
const ClearDiv = styled.div`
    padding: 10px;
    background-color: #e8f5f0;
    &:hover {
        background-color: #0fa76f;
        color: #fff;
    }
    cursor: pointer;
`;
const DateRange = styled.div``;
const SelectionLists = styled.div`
    max-height: 203px;
    overflow-y: scroll;
    min-height: 163px;

    &.search {
        max-height: 163px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ClassName = styled.div`
    display: block;
    cursor: pointer;
    padding: 12px 10px;
    border-bottom: 1px solid #f4f4f4;
    &:hover {
        background-color: #0fa76f;
        color: #fff;
    }
    &.active {
        background-color: #0fa76f;
        color: #fff;
    }
`;
const DropDownText = styled.div`
    display: block;
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #f4f4f4;
    &:hover {
        background-color: #0fa76f;
        color: #fff;
    }
`;
const LoaderBox = styled.div`
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NoData = styled.span`
    width: 100%;
    height: ${({ type }) =>
        type === "ward" || type === "municipality"
            ? "calc(100% - 40px)"
            : "100%"};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-family: Inter-Medium;
`;
