import React from 'react'
import Lottie from 'react-lottie'
import loader from "../../../assets/lotties/page-loader.json";

function DropLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
      }
      return <Lottie options={defaultOptions} height={35} width={35} />
}

export default DropLoader