import React from 'react'
import Lottie from 'react-lottie'
import loader from '../../../assets/animations/loader.json'

export default function ButtonLoader({w, h}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {},
  }
  return <Lottie options={defaultOptions} height={h ? h : 35} width={w? w : 31} />
}
