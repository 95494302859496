import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import CountUp from "react-countup";

function ExeCounts({ programes, manager, profile }) {
    const { id } = useParams();
    return (
        <MainContainer>
            {profile == "program_manager" ? (
                <>
                    <Container profile={profile}>
                        <Counts
                            to={
                                id
                                    ? "program-assistants/"
                                    : "/team/program-assistants/"
                            }
                        >
                            <Top>Program Assistants</Top>
                            <Bottom>
                                <Count>
                                    {programes.program_assistants ? (
                                        <>
                                            {
                                                <CountUp
                                                    end={
                                                        programes.program_assistants
                                                    }
                                                    duration={1}
                                                    start={0}
                                                />
                                            }
                                        </>
                                    ) : (
                                        "0"
                                    )}
                                </Count>
                                <Arrow>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                                        alt="Arrow"
                                    />
                                </Arrow>
                            </Bottom>
                        </Counts>
                        <Line></Line>
                        <Counts
                            to={
                                id
                                    ? "program-associates/"
                                    : "/team/program-associates/"
                            }
                        >
                            <Top>Program Associates</Top>
                            <Bottom>
                                <Count>
                                    {programes.program_associates ? (
                                        <>
                                            {
                                                <CountUp
                                                    end={
                                                        programes.program_associates
                                                    }
                                                    duration={1}
                                                    start={0}
                                                />
                                            }
                                        </>
                                    ) : (
                                        "0"
                                    )}
                                </Count>
                                <Arrow>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                                        alt="Arrow"
                                    />
                                </Arrow>
                            </Bottom>
                        </Counts>

                        <Counts
                            to={
                                id
                                    ? "program-executives/"
                                    : "/team/program-executives/"
                            }
                        >
                            <Top>Program Executives</Top>
                            <Bottom>
                                <Count>
                                    {programes.program_executives ? (
                                        <>
                                            {
                                                <CountUp
                                                    end={
                                                        programes.program_executives
                                                    }
                                                    duration={1}
                                                    start={0}
                                                />
                                            }
                                        </>
                                    ) : (
                                        "0"
                                    )}
                                </Count>
                                <Arrow>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                                        alt="Arrow"
                                    />
                                </Arrow>
                            </Bottom>
                        </Counts>
                    </Container>
                    <WardsCountDiv>
                        <WardCount to="/locations/wards/">
                            <Top>Wards</Top>
                            <WardCountText>
                                <p>
                                    {programes.wards ? (
                                        <>
                                            {
                                                <CountUp
                                                    end={programes.wards}
                                                    duration={1}
                                                    start={0}
                                                />
                                            }
                                        </>
                                    ) : (
                                        "0"
                                    )}
                                </p>
                                <Arrow>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                                        alt="Arrow"
                                    />
                                </Arrow>
                            </WardCountText>
                        </WardCount>
                        <WardCount to="/locations/panchayats/">
                            <Top>Panchayats</Top>
                            <WardCountText>
                                <p>
                                    {programes.panchayats ? (
                                        <>
                                            {
                                                <CountUp
                                                    end={programes.panchayats}
                                                    duration={1}
                                                    start={0}
                                                />
                                            }
                                        </>
                                    ) : (
                                        "0"
                                    )}
                                </p>
                                <Arrow>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                                        alt="Arrow"
                                    />
                                </Arrow>
                            </WardCountText>
                        </WardCount>
                    </WardsCountDiv>
                </>
            ) : (
                <Container>
                    <Counts
                        to={
                            id
                                ? "program-assistants/"
                                : "/team/program-assistants/"
                        }
                    >
                        <Top>Program Assistants</Top>
                        <Bottom>
                            <Count>
                                {programes.program_assistants
                                    ? programes.program_assistants
                                    : "0"}
                            </Count>
                            <Arrow>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                                    alt="Arrow"
                                />
                            </Arrow>
                        </Bottom>
                    </Counts>
                    <Line></Line>
                    <Counts to="/locations/wards/">
                        <Top>Wards</Top>
                        <Bottom>
                            <Count>
                                {programes.wards ? (
                                    <>
                                        {
                                            <CountUp
                                                end={programes.wards}
                                                duration={1}
                                                start={0}
                                            />
                                        }
                                    </>
                                ) : (
                                    "0"
                                )}
                            </Count>
                            <Arrow>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                                    alt="Arrow"
                                />
                            </Arrow>
                        </Bottom>
                    </Counts>

                    <Counts to="/locations/panchayats/">
                        <Top>Panchayats</Top>
                        <Bottom>
                            <Count>
                                {programes.panchayats ? (
                                    <>
                                        {
                                            <CountUp
                                                end={programes.panchayats}
                                                duration={1}
                                                start={0}
                                            />
                                        }
                                    </>
                                ) : (
                                    "0"
                                )}
                            </Count>
                            <Arrow>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                                    alt="Arrow"
                                />
                            </Arrow>
                        </Bottom>
                    </Counts>
                </Container>
            )}
        </MainContainer>
    );
}

export default ExeCounts;

const MainContainer = styled.div``;
const Container = styled.div`
    display: flex;
    grid-gap: 12px;
    padding: ${({ profile }) =>
        profile == "program_manager" ? "0 15px" : "0"};
    @media all and (max-width: 768px) {
        flex-wrap: wrap;
    }
`;
const Top = styled.p`
    font-size: 16px;
    font-family: "Inter-Medium";
    display: flex;
    align-items: center;
    @media all and (max-width: 768px) {
    }
`;
const Counts = styled(Link)`
    width: 33%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #e2e2e2;
    background-color: #fafafa;
    border-radius: 12px;
    &:hover {
        border: 1px solid rgba(15, 167, 111, 0.24);
        background-color: #fffaf0;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;
const WardsCountDiv = styled.div`
    display: flex;
    grid-gap: 15px;
    margin: 15px 0 0 0;
    border-top: 1px solid #e2e2e2;
    padding: 15px 15px 0 15px;
    @media all and (max-width: 640px) {
        flex-wrap: wrap;
    }
`;
const WardCount = styled(Link)`
    background-color: #f4f4f4;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    padding: 16px;
    cursor: pointer;
    border: 1px solid #e2e2e2;
    &:hover {
        border: 1px solid rgba(15, 167, 111, 0.24);
        background-color: #fffaf0;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const WardCountText = styled.div`
    display: flex;
    align-items: center;
    p {
        font-size: 20px;
        font-family: "Inter-Medium";
        margin-right: 15px;
    }
`;
const Line = styled.div`
    width: 1px;
    border-left: 1px solid #d3d3d3;
    @media all and (max-width: 980px) {
        display: none;
    }
`;
const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Count = styled.p`
    font-size: 22px;
    font-family: "Inter-Medium";
    color: #333;
`;
const Arrow = styled.span`
    display: inline-block;
    height: 15px;
    padding-right: 10px;
    @media all and (max-width: 768px) {
        margin-left: 15px;
    }
    img {
        height: 100%;
        display: block;
    }
`;
