import React from "react";
import Lottie from "react-lottie";
import clockTimer from "../../../assets/lotties/clock-timer.json";

function ClockTimer({height, width}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: clockTimer,
        rendererSettings: {},
    };
    return (
        <Lottie
            options={defaultOptions}
            height={height ? height : 60}
            width={width ? width : 60}
        />
    );
}

export default ClockTimer;
