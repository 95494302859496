import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import { useParams, useSearchParams } from "react-router-dom";

// ============  Components  =========
import { Context } from "../../context/Store";
import ProfileWidget from "../../includes/profile/ProfileWidget";
import ExaminationDetail from "../../includes/teams/ExaminationDetail";
import { textStyle } from "../../utils/constants";
import PeopleCard from "../../includes/general/dashboard/PeopleCard";
import ProgramCounts from "../../includes/teams/ProgramCounts";
import GeneralHeading from "../../includes/general/dashboard/GeneralHeading";
import AllWinnersCard from "../../includes/teams/AllWinnersCard";
import { axiosConfig } from "../../../axiosConfig";
import profile from "../../../assets/images/profile.png";
import FilledButton from "../../includes/profile/FilledButton";
import PageTitle from "../../includes/PageTitle";
import EditDetails from "../../includes/modals/EditDetails";
import SectionLoader from "../../includes/loaders/SectionLoader";
import CreateDetails from "../../includes/modals/CreateDetails";
import ShareScholarship from "../../includes/profile/ShareScholarship";

function ProgramAssociatesSingle() {
    const [programAssistants, setProgramAssistants] = useState([]);
    const [isAssistantsLoading, setAssistantsLoading] = useState(false);
    const [programAssociate, setProgramAssociate] = useState({});
    const [paginationData, setPaginationData] = useState({});
    const {
        state: {
            user_data: { access_token, profile_pk },
        },
    } = useContext(Context);
    const [isReload, setReload] = useState(false);
    const { state } = useContext(Context);
    const { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    // const [isAssistantLoading, setAssistantLoading] = useState(false);
    const [showModal, setModal] = useState(false);
    const [counts, setCounts] = useState({});
    const [posters, setPosters] = useState([
        {
            id: 1,
            poster: require("../../../assets/images/posters/poster1.jpeg"),
        },
        {
            id: 2,
            poster: require("../../../assets/images/posters/poster2.jpeg"),
        },
        {
            id: 3,
            poster: require("../../../assets/images/posters/poster3.jpeg"),
        },
        {
            id: 4,
            poster: require("../../../assets/images/posters/poster4.jpeg"),
        },

        {
            id: 5,
            poster: require("../../../assets/images/posters/poster5.jpeg"),
        },
        {
            id: 6,
            poster: require("../../../assets/images/posters/poster6.jpeg"),
        },
    ]);

    const [EditModal, setEditModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [editUser, setEditUser] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1);
    const currentParams = Object.fromEntries([...searchParams]);
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    const getFilterParams = () => {
        let filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));

        return filterParams;
    };
    useEffect(() => {
        const params = getFilterParams();
        var page;
        params.page && (page = parseInt(params.page - 1));
        params.page && handlePageClick({ selected: page });
    }, []);
    const NextLabel = () => {
        return (
            <img
                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                alt="Prev Arrow"
            />
        );
    };
    const PrevLabel = () => {
        return (
            <img
                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/left-arrow.svg"
                alt="Prev Arrow"
            />
        );
    };

    useEffect(() => {
        setAssistantsLoading(true);
        const params = getFilterParams();
        axiosConfig
            .get(`programs/program-assistants/${id ? id : profile_pk}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then((response) => {
                const { StatusCode, data, pagination_data } = response.data;

                if (StatusCode === 6000) {
                    setProgramAssistants(data);
                    setPaginationData(pagination_data);
                    setAssistantsLoading(false);
                } else if (StatusCode === 6001) {
                    setAssistantsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setAssistantsLoading(false);
            });
    }, [searchParams, isReload]);

    useEffect(() => {
        setLoading(true);
        axiosConfig
            .get(`accounts/edit-user-view/${id}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setProgramAssociate(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, [isReload]);

    useEffect(() => {
        setLoading(true);
        axiosConfig
            .get(`accounts/chief-user/${id ? id : profile_pk}/view/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setProgramAssociate(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, []);
    useEffect(() => {
        setLoading(true);
        axiosConfig
            .get(`accounts/chief-user/count/${id ? id : profile_pk}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { data, StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setCounts(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, []);
    const [examWinners, setExamWinners] = useState([
        {
            id: 1,
            name: "Muhammed Ali Khan",
            ward: "Elambilakod Ward",
            image: profile,
        },
        {
            id: 2,
            name: "Anjana Sivadasan",
            ward: "Amaranbalam Ward",
            image: profile,
        },
    ]);

    //  Preventing back scroll while a modal is active
    useEffect(() => {
        showModal || EditModal
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "unset");
    }, [showModal, EditModal]);

    return (
        <>
            <CreateDetails
                setCreateModal={setModal}
                CreateModal={showModal}
                userType="program_assistant"
                createUser={isReload}
                setReload={setReload}
                teamLead={programAssociate}
            />
            <EditDetails
                setReload={setReload}
                EditModal={EditModal}
                setEditModal={setEditModal}
                userType="program_assistant"
                chiefID={selectedUser}
                editUser={editUser}
            />
            <MainContainer id="main">
                <Top>
                    <PageTitle
                        name={programAssociate.name}
                        tab="Home"
                        section="Team"
                        designation="Program Associates"
                    />
                    <AddButton
                        onClick={() => {
                            setModal(!showModal);
                            setReload(!isReload);
                        }}
                    >
                        <FilledButton
                            icon={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/add-button.svg"
                            }
                            title="Create"
                        />
                    </AddButton>
                </Top>
                <ProfileWidget
                    profile="program_associate"
                    manager={programAssociate}
                />
                <MiniCover className="inner-wrapper">
                    <Cover>
                        <ShareContainer>
                            <ShareScholarship
                                posters={posters}
                                isLoading={isLoading}
                            />
                        </ShareContainer>
                        <BottomContainer>
                            <ProgramManagers>
                                <GeneralHeading
                                    title={"Program Assistants"}
                                    count={
                                        paginationData.total_items
                                            ? paginationData.total_items
                                            : ""
                                    }
                                />
                                {isAssistantsLoading ? (
                                    <EmptyBox>
                                        <SectionLoader />
                                    </EmptyBox>
                                ) : programAssistants.length > 0 ? (
                                    <AssociatesList>
                                        <PeopleCard
                                            programers={programAssistants}
                                            location="/team/program-assistants/"
                                            setEditModal={setEditModal}
                                            setSelectedUser={setSelectedUser}
                                            setEditUser={setEditUser}
                                        />
                                    </AssociatesList>
                                ) : (
                                    <EmptyBox>No Members Found !</EmptyBox>
                                )}
                                {paginationData.total_pages > 1 &&
                                    programAssistants.length > 0 && (
                                        <PaginationDiv>
                                            <PageCount>
                                                {paginationData.first_item} to{" "}
                                                {paginationData.last_item} of{" "}
                                                {paginationData.total_items}{" "}
                                                Results
                                            </PageCount>
                                            <PageLink>
                                                <ReactPaginate
                                                    nextLabel={<NextLabel />}
                                                    previousLabel={
                                                        <PrevLabel />
                                                    }
                                                    pageRangeDisplayed={1}
                                                    onPageChange={
                                                        handlePageClick
                                                    }
                                                    className="pagination"
                                                    pageClassName="pagination__li"
                                                    activeClassName="pagination__li__active"
                                                    breakClassName="pagination__break__li"
                                                    breakLinkClassName="pagination__break__a"
                                                    previousClassName="pagination__previous__li"
                                                    previousLinkClassName="pagination__previous__a"
                                                    nextClassName="pagination__next__li"
                                                    nextLinkClassName="pagination__next__a"
                                                    disabledClassName="pagination_disabled_btns"
                                                    pageCount={
                                                        paginationData.total_pages
                                                    }
                                                />
                                            </PageLink>
                                        </PaginationDiv>
                                    )}
                            </ProgramManagers>
                        </BottomContainer>
                    </Cover>
                    <Cover>
                        <CongratulationsDiv>
                            <GeneralHeading title="All Winners" />
                            <AllWinnersCard winnersDetails={examWinners} />
                        </CongratulationsDiv>
                        <CountsDiv>
                            <MiddleDiv>
                                <ProgramCounts
                                    profile={programAssociate}
                                    counts={counts}
                                />
                            </MiddleDiv>
                            <ExaminationCompletedDiv>
                                <ExaminationDetail />
                            </ExaminationCompletedDiv>
                        </CountsDiv>
                    </Cover>
                </MiniCover>
            </MainContainer>
        </>
    );
}

export default ProgramAssociatesSingle;

const ShareContainer = styled.div`
    margin: 24px 0;
    @media all and (max-width: 480px) {
        margin: 12px 0px;
    }
`;

const PaginationDiv = styled.div`
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
`;
const PageLink = styled.div``;
const PageCount = styled.p`
    font-size: 14px;
    font-family: "Inter-Medium";
    color: #aaa;
`;

const CountsDiv = styled.div`
    display: flex;
    flex-direction: column;
    @media all and (max-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    @media all and (max-width: 768px) {
        flex-direction: column;
    }
`;
const AssociatesList = styled.div`
    min-height: 350px;
`;
const EmptyBox = styled.div`
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: "Inter-Medium";
`;

const MainContainer = styled.div``;
const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
        margin-bottom: 18px;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const BottomContainer = styled.div`
    margin-top: 24px;
    @media all and (max-width: 768px) {
        margin-top: 15px;
    }
`;
const ProgramManagers = styled.div`
    background-color: #fff;
    border-radius: 16px;
    width: 100%;
    border: 1px solid #e2e2e2;
    padding: 16px;
`;
const HeaderComponent = styled.div`
    h3 {
        margin-bottom: 10px;
    }
`;

const Cover = styled.div`
    width: 100%;
`;
const MiniCover = styled.div`
    grid-template-columns: 8fr 4fr !important;
    @media all and (max-width: 980px) {
        grid-template-columns: none !important;
    }
    @media all and (max-width: 768px) {
        display: unset !important;
    }
`;

const TitleTab = styled.div`
    h4 {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 18px;
        font-family: "Inter-Regular";
    }
    span {
        margin: 0 6px;
        color: #333;
        opacity: 0.5;
        &:first-child {
            margin-left: 0;
        }
        &.bold {
            opacity: 1;
        }
    }
`;
const CongratulationsDiv = styled.div`
    margin: 24px 0;
    background-color: #fff;
    padding: 14px;
    border-radius: 16px;
    border: 1px solid #d3d3d3;
    @media all and (max-width: 980px) {
        margin: 0 0 24px 0;
    }
    @media all and (max-width: 768px) {
        margin: 15px 0;
    }
`;
const ExaminationCompletedDiv = styled.div`
    display: flex;
    @media all and (max-width: 980px) {
        width: calc(50% - 10px);
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;

const AddButton = styled.div`
    @media all and (max-width: 480px) {
        margin-top: 15px;
    }
`;
const Icon = styled.span`
    display: inline-block;
    width: 20px;
    margin-right: 15px;
    img {
        width: 100%;
        display: block;
    }
`;

const MiddleDiv = styled.div`
    margin-bottom: 25px;
    @media all and (max-width: 980px) {
        width: calc(50% - 10px);
        margin-bottom: 0;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;
