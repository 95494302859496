import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Context } from '../../context/Store'

const PrivateRoute = ({ children }) => {
  const { state } = useContext(Context)
  const is_verified = state.user_data.is_verified
  const access_token = state.user_data.access_token

  const location = useLocation()
 
  return is_verified && access_token ? (
    children
  ) : (
    <Navigate
      to={
        location.pathname
          ? `auth/login?next=${location.pathname}${location.search}`
          : 'auth/login'
      }
    />
  )
}

export default PrivateRoute
