import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FilledButton from "../profile/FilledButton";
import ShareButton from "../profile/ShareButton";

function ExaminationDetail() {
    const examDetail = {
        exam_status: "not-started",
        exam_date: "30, April 2023",
        exam_time: "9:00 AM to 10:00 PM",
    };
    const [textBreak, setTextBreak] = useState(false);
    useEffect(() => {
        const box = document.getElementById("detail");
        function handleResize() {
            if (box.offsetWidth <= 330) {
                setTextBreak(true);
            } else {
                setTextBreak(false);
            }
        }
		if (box.offsetWidth <= 330) {
			setTextBreak(true);
		} else {
			setTextBreak(false);
		}
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
    return (
        <MainContainer>
            <Container>
                <TitleDiv>
                    <Icon>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/exam-pen.svg"
                            alt="File"
                        />
                    </Icon>

                    {examDetail?.exam_status === "not-started" ? (
                        <Text>Examination Date</Text>
                    ) : (
                        <Text>Exam Completed</Text>
                    )}
                </TitleDiv>
                <ExamDetail id="detail" textBreak={textBreak}>
                    <ExamDate textBreak={textBreak}>
                        <ExamIcons>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/calendar.svg"
                                alt="Calendar"
                            />
                        </ExamIcons>
                        <p>{examDetail.exam_date}</p>
                    </ExamDate>
                    <ExamDate>
                        <ExamIcons>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/clock.svg"
                                alt="Calendar"
                            />
                        </ExamIcons>
                        <p>{examDetail.exam_time}</p>
                    </ExamDate>
                </ExamDetail>
            </Container>
            {/* {examDetail?.exam_status === "not-started" && (
				<DownloadDiv>
					<Download>
						<DownloadIcon>
							<img
								src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/download.svg'
								alt="Download"
							/>
						</DownloadIcon>
						<p></p>
					</Download>
					<Share>
						<FilledButton icon={'https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/share.svg'} />
					</Share>
				</DownloadDiv>
			)} */}
        </MainContainer>
    );
}

export default ExaminationDetail;

const MainContainer = styled.div`
    border-radius: 16px;
    border: 1px solid #d3d3d3;
    padding: 10px;
    background-color: #fff;
    width: 100%;
`;
const Container = styled.div`
    background: #f4f4f4;
    border-radius: 12px;
    padding: 15px;
    @media all and (max-width: 480px) {
        padding: 10px;
    }
`;
const TitleDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
`;
const Icon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    padding: 8px;
    margin-right: 16px;
    img {
        width: 100%;
        display: block;
    }
`;
const Text = styled.p`
    font-size: 16px;
    font-family: "Inter-Medium";
    color: #333;
`;

const ExamDetail = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: ${({ textBreak }) => (textBreak ? "column" : "row")};
    @media all and (max-width: 1280px) {
        flex-direction: column;
    }
`;
const ExamDate = styled.div`
    display: flex;
    align-items: center;
	margin-bottom: ${({textBreak}) => textBreak ? '5px':"0"} ;
    p {
        font-family: "Inter-medium";
        font-size: 16px;
        color: #333;
    }
`;
const ExamIcons = styled.span`
    display: inline-block;
    width: 20px;
    margin-right: 8px;
    img {
        width: 100%;
        display: block;
    }
`;

const DownloadDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
`;
const Download = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 6px 8px;
    border: 1px solid #0fa76f;
    margin-right: 15px;
    p {
        color: #0fa76f;
        font-size: 14px;
        font-family: "Inter-Medium";
    }
`;
const Share = styled.div``;
const DownloadIcon = styled.span`
    display: inline-block;
    width: 20px;
    margin-right: 10px;
    img {
        width: 100%;
        display: block;
    }
`;
// const DownloadDiv = styled.div``
