import React, { useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";
import Highlighter from "react-highlight-words";
import styled from "styled-components";

import ButtonLoader from "../loaders/ButtonLoader";
import { axiosConfig } from "../../../axiosConfig";
import { Context } from "../../context/Store";
import SectionLoader from "../loaders/SectionLoader";
import UseOutsideClick from "../UseOutsideClick";

function EditDetails({
    EditModal,
    setEditModal,
    userType,
    chiefID,
    editUser,
    setReload,
}) {
    const [hide, setHide] = useState(true);
    const [isError, setError] = useState(false);
    const [reload, serReload] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [defaultData, setDefaultData] = useState("");
    const [defaultDataLoading, setDefaultLoading] = useState(false);

    const [districtDropDown, setDistrictDropDown] = useState(false);
    const [districtsList, setDistrictsList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const [selectedDistrictID, setSelectedDistrictID] = useState("");

    const [teamLeads, setTeamLeads] = useState([]);
    const [leadDropdown, setLeadDropdown] = useState(false);
    const [selectedLeadID, setSelectedLeadID] = useState("");
    const [selectedLead, setSelectedLead] = useState("");
    const [selectedProgramme, setSelectedProgramme] = useState("");
    const [searchLead, setSearchLead] = useState("");

    const [localbodies, setLocalbodies] = useState([]);
    const [selectedLocalbody, setSelectedLocalbody] = useState("");
    const [selectedLocalbodyID, setSelectedLocalbodyID] = useState("");
    const [localbodyDropDown, setLocalbodyDropDown] = useState(true);

    const [wardsList, setWardsList] = useState([]);
    const [selectedWardID, setSelectedWardID] = useState("");
    const [wardDropDown, setWardDropDown] = useState(true);
    const [selectedWard, setSelectedWard] = useState("");

    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [uploadedPhoto, setUploadedPhoto] = useState(null);
    const [userImageURL, setUserImageURL] = useState("");
    const [collegeName, setCollegeName] = useState("");
    const [collegeBranch, setCollegeBranch] = useState("");
    const [studyYear, setStudyYear] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const { state } = useContext(Context);
    const access_token = state.user_data.access_token;

    useEffect(() => {
        setLoading(true);
        axiosConfig
            .get("locations/districts/")
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setDistrictsList(data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, []);

    // Clearing The Values
    const clearValues = () => {
        setUserImageURL("");
        setUploadedPhoto("");
        setUserName("");
        setSelectedLead("");
        setSelectedLeadID("");
        setPhoneNumber("");
        setEmail("");
        setSelectedDistrict("");
        setSelectedDistrictID("");
        setSelectedLead("");
        setSelectedLocalbody("");
        setSelectedLocalbodyID("");
        setSelectedWard("");
        setSelectedWardID("");
        setCollegeName("");
        setCollegeBranch("");
        setStudyYear("");
    };
    // Updating DefaultDatas to States
    useEffect(() => {
        defaultData && defaultData.photo && setUserImageURL(defaultData.photo);
        defaultData && defaultData.name && setUserName(defaultData.name);
        defaultData && defaultData.phone && setPhoneNumber(defaultData.phone);
        defaultData && defaultData.email && setEmail(defaultData.email);
        defaultData &&
            defaultData.district &&
            setSelectedDistrict(defaultData.district);
        defaultData &&
            defaultData.district_pk &&
            setSelectedDistrictID(defaultData.district_pk);
        defaultData &&
            defaultData.localbody &&
            setSelectedLocalbody(defaultData.localbody);
        defaultData && defaultData.ward && setSelectedWard(defaultData.ward);
        defaultData &&
            defaultData.college &&
            setCollegeName(defaultData.college);
        defaultData &&
            defaultData.branch &&
            setCollegeBranch(defaultData.branch);
        defaultData &&
            defaultData.year_of_study &&
            setStudyYear(defaultData.year_of_study);
    }, [defaultData]);

    const fetchUserData = () => {
        setDefaultLoading(true);
        chiefID &&
            axiosConfig
                .get(`accounts/edit-user-view/${chiefID}/`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    const { data, StatusCode } = response.data;
                    if (StatusCode === 6000) {
                        setDefaultData(data);
                        setSelectedLead(data.assigned_to);
                        setSelectedLeadID(data?.assigned_to?.pk);
                        setDefaultLoading(false);
                    } else if (StatusCode === 6001) {
                        setDefaultLoading(false);
                    } else {
                        setDefaultLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setDefaultLoading(false);
                });
    };
    useEffect(() => {
        selectedDistrictID &&
            axiosConfig
                .get(`locations/localbodies/${selectedDistrictID}/`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: {
                        q: selectedLocalbody,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setLocalbodies(data);
                    } else {
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    }, [selectedDistrictID, selectedLocalbody]);

    useEffect(() => {
        setLoading(true);
        selectedDistrictID &&
            axiosConfig
                .get(`locations/wardcode/${selectedDistrictID}/`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    params: {
                        q: selectedWard,
                        localbody: selectedLocalbodyID,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setLoading(false);
                        setWardsList(data);
                    } else if (StatusCode === 6001) {
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
    }, [selectedWard]);

    // Function Renders when clicked outside of district dropdown
    const districtOutsideClick = () => {
        setDistrictDropDown(false);
    };

    const leadOutClick = () => {
        setLeadDropdown(false);
    };
    const localOutsideClick = () => {
        setLocalbodyDropDown(false);
    };
    const wardOutsideClick = () => {
        setWardDropDown(false);
    };

    const listManagers = () => {
        setLoading(true);
        setSelectedProgramme("Program Manager");
        axiosConfig
            .get("/programs/program-managers/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    q: searchLead,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTeamLeads(data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };
    const listExecutives = () => {
        setSelectedProgramme("Program Executive");
        setLoading(true);
        axiosConfig
            .get("/programs/program-executives/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    q: searchLead,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTeamLeads(data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };
    const listAssociates = () => {
        setSelectedProgramme("Program Associate");
        setLoading(true);
        axiosConfig
            .get("/programs/program-associates/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    q: searchLead,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTeamLeads(data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    // =================( Assigning Team Leads )=================

    useEffect(() => {
        if (userType === "program_manager") {
            // Program head PK live
            setSelectedLeadID("d64ec8ea-4495-4cb1-a766-6261b7fb73d6");
            // Program head PK demo
            // setSelectedLeadID( "e0bcf62-54f2-49c8-945d-66b441867652")
        } else if (userType === "program_executive") {
            listManagers();
        } else if (userType === "program_associate") {
            listExecutives();
        } else if (userType === "program_assistant") {
            listAssociates();
        }
        fetchUserData();
    }, [editUser, searchLead]);
    const handlePhotoClick = () => {
        const button = document.getElementById("upload_image");
        button.click();
    };
    const uploadPhototoState = (e) => {
        const image = e.target.files[0];
        setUploadedPhoto(image);
        setUserImageURL(URL.createObjectURL(image));
    };
    // const validateForm = () => {
    //     var errorExists = false;
    //     if (!uploadedPhoto) {
    //         setError(true);
    //         setErrorMsg("Upload your photo");
    //         errorExists = true;
    //     } else if (!userName) {
    //         setError(true);
    //         setErrorMsg("Enter Your Name");
    //         errorExists = true;
    //     } else if (!password) {
    //         setError(true);
    //         setErrorMsg("Enter a strong password");
    //         errorExists = true;
    //     } else if (!selectedLead) {
    //         setError(true);
    //         setErrorMsg("Select your team lead");
    //         errorExists = true;
    //     } else if (!phoneNumber) {
    //         setError(true);
    //         setErrorMsg("Enter Your Number");
    //         errorExists = true;
    //     } else if (!selectedDistrictID) {
    //         setError(true);
    //         setErrorMsg("Select Your District");
    //         errorExists = true;
    //     } else if (!selectedLocalbodyID) {
    //         setError(true);
    //         setErrorMsg("Select Your Localbody");
    //         errorExists = true;
    //     } else if (!ward) {
    //         setError(true);
    //         setErrorMsg("Enter Your Ward name");
    //         errorExists = true;
    //     } else if (!collegeName) {
    //         setError(true);
    //         setErrorMsg("Enter Your College");
    //         errorExists = true;
    //     } else if (!collegeBranch) {
    //         setError(true);
    //         setErrorMsg("Enter Your Branch");
    //         errorExists = true;
    //     } else if (!studyYear) {
    //         setError(true);
    //         setErrorMsg("Enter Your Year of study");
    //         errorExists = true;
    //     } else {
    //         setError(false);
    //         setErrorMsg("");
    //         errorExists = false;
    //     }
    //     return errorExists;
    // };

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("photo", uploadedPhoto);
        formData.append("name", userName);
        formData.append("assigned_to", selectedLeadID);
        formData.append("phone", phoneNumber);
        formData.append("email", email);
        formData.append("country", "IN");
        formData.append("district", selectedDistrictID);

        formData.append("user_ward", selectedWardID);
        formData.append("college", collegeName);
        formData.append("branch", collegeBranch);
        formData.append("year_of_study", studyYear);

        setSubmitLoading(true);
        axiosConfig
            .post(`accounts/edit-user/${chiefID}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { data, StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setReload((prev) => !prev);
                    setEditModal(false);
                    clearValues();
                    setSubmitLoading(false);
                } else if (StatusCode === 6001) {
                    setSubmitLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setSubmitLoading(false);
            });
    };

    return (
        <MainContainer className={EditModal && "show"}>
            <Container>
                <Header>
                    <Heading>Edit User</Heading>
                    <CloseButton
                        onClick={() => {
                            setEditModal((prev) => !prev);
                            clearValues();
                        }}
                    >
                        <img
                            src={
                                require("../../../assets/icons/pros/close-circle.svg")
                                    .default
                            }
                            alt="Icon"
                        />
                    </CloseButton>
                </Header>
                <FormContainer>
                    <ProfileInput>
                        <ProfilePhoto
                            onClick={() => {
                                handlePhotoClick();
                            }}
                            image={
                                userImageURL
                                    ? userImageURL
                                    : require("../../../assets/icons/user-icon.svg")
                                          .default
                            }
                        ></ProfilePhoto>
                        <PhotoInputBox
                            type="file"
                            id="upload_image"
                            accept="image/*"
                            onChange={(e) => {
                                uploadPhototoState(e);
                            }}
                        />
                        <TypeName>
                            <InputLabel
                                className={!userName && isError && "error"}
                            >
                                Name*
                            </InputLabel>
                            <NameInput>
                                <InputForm
                                    className={!userName && isError && "error"}
                                    type="text"
                                    placeholder="Enter your Name"
                                    onChange={(e) => {
                                        setUserName(e.target.value);
                                    }}
                                    value={userName}
                                />
                            </NameInput>
                        </TypeName>
                    </ProfileInput>
                    <MiddleForm>
                        {userType !== "program_manager" && (
                            <UserEdit>
                                <UseOutsideClick onClickOutside={leadOutClick}>
                                    <InputLabel
                                        className={
                                            !selectedLead && isError && "error"
                                        }
                                    >
                                        Team lead*
                                    </InputLabel>
                                    <SelectedLeadDiv
                                        className={
                                            !selectedLead && isError && "error"
                                        }
                                        onClick={() => {
                                            setLeadDropdown(!leadDropdown);
                                        }}
                                    >
                                        <SelectedLead>
                                            {selectedLead ? (
                                                <SelectedLeadBox>
                                                    <LeadProfile>
                                                        {selectedLead.photo ? (
                                                            <Profile
                                                                image={
                                                                    selectedLead.photo
                                                                }
                                                            ></Profile>
                                                        ) : (
                                                            <AvatarDiv>
                                                                <Avatar
                                                                    name={
                                                                        selectedLead.name
                                                                    }
                                                                    size="40"
                                                                    round={true}
                                                                />
                                                            </AvatarDiv>
                                                        )}
                                                    </LeadProfile>
                                                    <LeadDetails>
                                                        <LeadName>
                                                            {selectedLead.name
                                                                ? selectedLead.name
                                                                : ""}
                                                        </LeadName>
                                                        <UserType>
                                                            {selectedProgramme}
                                                        </UserType>
                                                    </LeadDetails>
                                                </SelectedLeadBox>
                                            ) : (
                                                <small>Select Team Lead</small>
                                            )}
                                        </SelectedLead>
                                        <IconArrow
                                            className={leadDropdown && "rotate"}
                                        >
                                            <img
                                                src={
                                                    require("../../../assets/icons/Arrow.svg")
                                                        .default
                                                }
                                                alt="Icon"
                                            />
                                        </IconArrow>
                                    </SelectedLeadDiv>
                                    <TeamLeadDropdown
                                        className={leadDropdown && "active"}
                                    >
                                        <LocalBodySearch>
                                            <input
                                                type="text"
                                                value={searchLead}
                                                placeholder="Search Team Lead"
                                                onChange={(e) => {
                                                    setSearchLead(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <SearchIcon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/search-button.svg"
                                                    alt="Search"
                                                />
                                            </SearchIcon>
                                        </LocalBodySearch>
                                        {isLoading ? (
                                            <LoaderDiv>
                                                <SectionLoader />
                                            </LoaderDiv>
                                        ) : teamLeads.length > 0 ? (
                                            <TeamLeadDropBox>
                                                <div>
                                                    {teamLeads.map((lead) => (
                                                        <LeadBox
                                                            key={lead.pk}
                                                            onClick={() => {
                                                                setSelectedLeadID(
                                                                    lead.pk
                                                                );
                                                                setSelectedLead(
                                                                    lead
                                                                );
                                                                setLeadDropdown(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            <LeadProfile>
                                                                {lead.photo ? (
                                                                    <Profile
                                                                        image={
                                                                            lead.photo
                                                                        }
                                                                    ></Profile>
                                                                ) : (
                                                                    <AvatarDiv>
                                                                        <Avatar
                                                                            name={
                                                                                lead.name
                                                                            }
                                                                            size="40"
                                                                            round={
                                                                                true
                                                                            }
                                                                        />
                                                                    </AvatarDiv>
                                                                )}
                                                            </LeadProfile>
                                                            <LeadDetails>
                                                                <LeadName>
                                                                    {lead.name
                                                                        ? lead.name
                                                                        : ""}
                                                                </LeadName>
                                                                <UserType>
                                                                    {
                                                                        selectedProgramme
                                                                    }
                                                                </UserType>
                                                            </LeadDetails>
                                                        </LeadBox>
                                                    ))}
                                                </div>
                                            </TeamLeadDropBox>
                                        ) : (
                                            <LoaderDiv>No Data !</LoaderDiv>
                                        )}
                                    </TeamLeadDropdown>
                                </UseOutsideClick>
                            </UserEdit>
                        )}
                        <UserEdit>
                            <InputLabel>Phone Number*</InputLabel>
                            <Input className="phone">
                                <CountryDiv>
                                    <Flag
                                        flag={
                                            require("../../../assets/icons/flags.svg")
                                                .default
                                        }
                                    ></Flag>
                                    <Code>+91</Code>
                                </CountryDiv>
                                <InputField
                                    type="number"
                                    placeholder="Enter Phone Number"
                                    required
                                    onChange={(e) => {
                                        setPhoneNumber(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (
                                            e.keyCode === 101 ||
                                            e.keyCode === 69
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                    value={phoneNumber}
                                />
                            </Input>
                        </UserEdit>
                        <UserEdit>
                            <InputLabel>Email*</InputLabel>
                            <Input>
                                <InputField
                                    type="email"
                                    placeholder="Enter Your Email"
                                    required
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    value={email}
                                />
                            </Input>
                        </UserEdit>
                        <UserEdit>
                            <UseOutsideClick
                                onClickOutside={districtOutsideClick}
                            >
                                <InputLabel>District*</InputLabel>
                                <Input
                                    onClick={() => {
                                        setDistrictDropDown(!districtDropDown);
                                    }}
                                >
                                    <DistrictName>
                                        {selectedDistrict
                                            ? selectedDistrict
                                            : "Select District"}
                                    </DistrictName>
                                    <IconArrow
                                        className={districtDropDown && "rotate"}
                                    >
                                        <img
                                            src={
                                                require("../../../assets/icons/Arrow.svg")
                                                    .default
                                            }
                                            alt="Icon"
                                        />
                                    </IconArrow>
                                </Input>
                                <DistrictDrop
                                    className={districtDropDown && "active"}
                                >
                                    {isLoading ? (
                                        <LoaderDiv>
                                            <SectionLoader />
                                        </LoaderDiv>
                                    ) : districtsList.length > 0 ? (
                                        <>
                                            {districtsList.map((dist) => (
                                                <DistName
                                                    key={dist.id}
                                                    className={
                                                        selectedDistrict ===
                                                            dist.name &&
                                                        "active"
                                                    }
                                                    onClick={() => {
                                                        setDistrictDropDown(
                                                            false
                                                        );
                                                        setSelectedDistrict(
                                                            dist.name
                                                        );
                                                        setSelectedDistrictID(
                                                            dist.id
                                                        );
                                                        setSelectedWard("");
                                                        setSelectedWardID("");
                                                        setSelectedLocalbody(
                                                            ""
                                                        );
                                                        setSelectedLocalbodyID(
                                                            ""
                                                        );
                                                    }}
                                                >
                                                    {dist.name}
                                                </DistName>
                                            ))}
                                        </>
                                    ) : (
                                        <LoaderDiv>No Data !</LoaderDiv>
                                    )}
                                </DistrictDrop>
                            </UseOutsideClick>
                        </UserEdit>
                        <UserEdit>
                            <UseOutsideClick onClickOutside={localOutsideClick}>
                                <InputLabel>Local body*</InputLabel>
                                <Input
                                    onClick={() => {
                                        setLocalbodyDropDown(true);
                                    }}
                                >
                                    <InputField
                                        type="text"
                                        placeholder="Enter your local body"
                                        onChange={(e) => {
                                            setSelectedLocalbody(
                                                e.target.value
                                            );
                                        }}
                                        value={selectedLocalbody}
                                    />
                                    <IconArrow
                                        className={
                                            localbodyDropDown &&
                                            selectedLocalbody &&
                                            "rotate"
                                        }
                                    >
                                        <img
                                            src={
                                                require("../../../assets/icons/Arrow.svg")
                                                    .default
                                            }
                                            alt="Icon"
                                        />
                                    </IconArrow>
                                </Input>
                                <LocalbodyDropDown
                                    className={
                                        localbodyDropDown &&
                                        selectedLocalbody &&
                                        "active"
                                    }
                                >
                                    {isLoading ? (
                                        <LoaderDiv>
                                            <SectionLoader />
                                        </LoaderDiv>
                                    ) : localbodies.length > 0 ? (
                                        <>
                                            {localbodies.map((localbody) => (
                                                <LocalbodyName
                                                    key={localbody.id}
                                                    onClick={() => {
                                                        setSelectedLocalbody(
                                                            localbody.name
                                                        );
                                                        setLocalbodyDropDown(
                                                            false
                                                        );
                                                        setSelectedLocalbodyID(
                                                            localbody.id
                                                        );
                                                        setSelectedWard("");
                                                        setSelectedWardID("");
                                                    }}
                                                >
                                                    <Highlighter
                                                        highlightClassName="typed-text"
                                                        searchWords={[
                                                            selectedLocalbody,
                                                        ]}
                                                        textToHighlight={
                                                            localbody.name
                                                        }
                                                        autoEscape={true}
                                                    />
                                                </LocalbodyName>
                                            ))}
                                        </>
                                    ) : (
                                        <LoaderDiv>
                                            {selectedDistrict
                                                ? "No Data Found!"
                                                : "Select Your Local body"}
                                        </LoaderDiv>
                                    )}
                                </LocalbodyDropDown>
                            </UseOutsideClick>
                        </UserEdit>
                        <UserEdit>
                            <UseOutsideClick onClickOutside={wardOutsideClick}>
                                <InputLabel>Ward*</InputLabel>
                                <Input
                                    onClick={() => {
                                        setWardDropDown(true);
                                    }}
                                >
                                    <InputField
                                        type="text"
                                        placeholder="Enter ward or ward number"
                                        required
                                        onChange={(e) => {
                                            setSelectedWard(e.target.value);
                                        }}
                                        value={selectedWard}
                                    />
                                    <IconArrow
                                        className={
                                            wardDropDown &&
                                            selectedWard &&
                                            "rotate"
                                        }
                                    >
                                        <img
                                            src={
                                                require("../../../assets/icons/Arrow.svg")
                                                    .default
                                            }
                                            alt="Icon"
                                        />
                                    </IconArrow>
                                </Input>
                                <WardDropDown
                                    className={
                                        wardDropDown && selectedWard && "active"
                                    }
                                >
                                    {isLoading ? (
                                        <LoaderDiv>
                                            <SectionLoader />
                                        </LoaderDiv>
                                    ) : localbodies.length > 0 ? (
                                        <>
                                            {wardsList.map((ward) => (
                                                <LocalbodyName
                                                    key={ward.id}
                                                    onClick={() => {
                                                        setWardDropDown(false);
                                                        setSelectedWard(
                                                            ward.name
                                                        );
                                                        setSelectedWardID(
                                                            ward.id
                                                        );
                                                    }}
                                                >
                                                    <Highlighter
                                                        highlightClassName="typed-text"
                                                        searchWords={[
                                                            selectedWard,
                                                        ]}
                                                        textToHighlight={
                                                            ward.name
                                                        }
                                                        autoEscape={true}
                                                    />
                                                </LocalbodyName>
                                            ))}
                                        </>
                                    ) : (
                                        <LoaderDiv>
                                            {selectedDistrict
                                                ? "No Data Found!"
                                                : "Select Your District"}
                                        </LoaderDiv>
                                    )}
                                </WardDropDown>
                            </UseOutsideClick>
                        </UserEdit>
                        <UserEdit>
                            <InputLabel>College*</InputLabel>
                            <Input>
                                <InputField
                                    type="text"
                                    placeholder="Enter Your College Name"
                                    required
                                    onChange={(e) => {
                                        setCollegeName(e.target.value);
                                    }}
                                    value={collegeName}
                                />
                                {/* <IconArrow>
                                    <img
                                        src={require("../../../assets/icons/Arrow.svg").default}
                                        alt="Icon"
                                    />
                                </IconArrow> */}
                            </Input>
                        </UserEdit>
                        <UserEdit>
                            <InputLabel>Branch*</InputLabel>
                            <Input>
                                <InputField
                                    type="text"
                                    placeholder="Enter Branch"
                                    required
                                    onChange={(e) => {
                                        setCollegeBranch(e.target.value);
                                    }}
                                    value={collegeBranch}
                                />
                                {/* <IconArrow>
                                    <img
                                        src={require("../../../assets/icons/Arrow.svg").default}
                                        alt="Icon"
                                    />
                                </IconArrow> */}
                            </Input>
                        </UserEdit>
                        <UserEdit>
                            <InputLabel>Year of Study*</InputLabel>
                            <Input>
                                <InputField
                                    type="text"
                                    placeholder="Enter Year of Study"
                                    required
                                    onChange={(e) => {
                                        setStudyYear(e.target.value);
                                    }}
                                    value={studyYear}
                                />
                                {/* <IconArrow>
                                    <img
                                        src={require("../../../assets/icons/Arrow.svg").default}
                                        alt="Icon"
                                    />
                                </IconArrow> */}
                            </Input>
                        </UserEdit>
                    </MiddleForm>
                    <FooterButton>
                        <CancelButton
                            onClick={() => {
                                setEditModal(false);
                                clearValues();
                            }}
                        >
                            Cancel
                        </CancelButton>
                        <SubmitButton
                            onClick={(e) => {
                                handleSubmitForm(e);
                            }}
                        >
                            {isSubmitLoading ? (
                                <ButtonLoader w={25} h={25} />
                            ) : (
                                "Submit"
                            )}
                        </SubmitButton>
                    </FooterButton>
                </FormContainer>
            </Container>
        </MainContainer>
    );
}

export default EditDetails;

const MainContainer = styled.div`
    transition: 200ms ease all;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(0);
    background-color: rgba(211, 211, 211, 0.3);
    backdrop-filter: blur(2px);
    z-index: 9999;
    overflow-y: hidden;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    &.show {
        transform: scale(1);
    }
`;
const LocalbodyName = styled.div`
    font-family: Inter-Medium;
    padding: 15px 20px;
    border-radius: 8px;
    &.active {
        background-color: #e8f5f0;
    }
    & span {
        font-family: Inter-Medium;
        font-size: 14px;
    }
    &:hover {
        background-color: #e8f5f0;
    }
    color: #969696;
    font-family: Inter-Regular;
    font-size: 14px;
    & .typed-text {
        color: #009262;
    }
`;
const Container = styled.div`
    width: 750px;
    background-color: #fff;
    padding: 32px;
    max-height: 90vh;
    overflow-y: scroll;
    border-radius: 8px;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1180px) {
        width: 670px;
    }
    @media all and (max-width: 980px) {
        width: 500px;
        /* overflow-y: scroll; */
    }
    @media all and (max-width: 768px) {
        width: 460px;
    }
    @media all and (max-width: 640px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 95%;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;

    @media all and (max-width: 980px) {
        margin-bottom: 10px;
    }
`;
const Heading = styled.h3`
    font-size: 18px;
    font-family: Inter-Medium;
    color: #333333; ;
`;
const CloseButton = styled.span`
    display: inline-block;
    width: 30px;
    height: 30px;
    img {
        width: 100%;
        display: block;
    }
    cursor: pointer;
`;
const FormContainer = styled.div`
    /* max-height: 80vh; */
    /* overflow-y: scroll; */
    font-family: Inter-Regular;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ProfileInput = styled.div`
    display: flex;
    margin-bottom: 30px;
    position: relative;

    @media all and (max-width: 1180px) {
        margin-bottom: 20px;
    }
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
    }
`;
const ProfilePhoto = styled.span`
    cursor: pointer;
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: ${({ image }) => image && `url(${image})`};
    display: inline-block;
    margin-right: 40px;
    position: relative;
    border-radius: 50%;
    &::after {
        position: absolute;
        bottom: -11px;
        right: -8px;
        background-color: #fafafa;
        content: "";
        width: 32px;
        height: 32px;
        background-image: ${`url(${
            require("../../../assets/icons/edit-icon.svg").default
        })`};
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        /* overflow:visible; */
    }
    img {
        display: block;
        width: 100%;
        height: 100%;
    }

    @media all and (max-width: 1180px) {
    }
    @media all and (max-width: 1080px) {
        margin-right: 20px;
    }
    @media all and (max-width: 980px) {
        margin-right: 0px;
    }
    @media all and (max-width: 768px) {
    }
`;
const TypeName = styled.span`
    margin-top: 30px;
    width: 100%;

    @media all and (max-width: 1180px) {
        margin-top: 20px;
    }
`;
const PhotoInputBox = styled.input`
    width: 0;
    height: 0;
    visibility: hidden;
`;
// const EditIcon = styled.span`
//     cursor: pointer;
//     position: absolute;
//     right: 87%;
//     width: 33px;
//     bottom: 5%;
//     display: inline-block;
//     input {
//         width: 0;
//         height: 0;
//         visibility: hidden;
//     }
//     img {
//         display: block;
//         width: 100%;
//     }

//     @media all and (max-width: 1180px) {
//         right: 86%;
//         bottom: 0%;
//     }
//     @media all and (max-width: 1080px) {
//         right: 85%;
//         bottom: -5%;
//     }
//     @media all and (max-width: 980px) {
//         right: 41%;
//         bottom: 45%;
//     }
//     @media all and (max-width: 768px) {
//         right: 44%;
//         bottom: 46%;
//     }
//     @media all and (max-width: 640px) {
//         right: 45%;
//     }
//     @media all and (max-width: 480px) {
//         right: 40%;
//     }
//     @media all and (max-width: 360px) {
//         right: 40%;
//         bottom: 48%;
//     }
// `;

const NameInput = styled.div``;
const InputLabel = styled.label`
    font-size: 14px;
    font-family: Inter-Medium;
    color: #686868;
    &.error {
        color: #f05858;
    }
`;
const InputForm = styled.input`
    padding: 18px 16px;
    border: 1px solid #f4f4f4;
    border-radius: 8px;
    background: #fafafa;
    width: 100%;
    font-size: 14px;
    &.error {
        border: 1px solid red;
    }
`;

const MiddleForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;
const UserEdit = styled.div`
    position: relative;
    width: 48%;
    height: 72px;
    margin-bottom: 20px;
    cursor: pointer;

    @media all and (max-width: 980px) {
        width: 100%;
    }
`;

const Input = styled.div`
    border: 1px solid #f4f4f4;
    border-radius: 8px;
    background: #fafafa;
    justify-content: space-between;
    padding: 0px 16px;
    display: flex;
    width: 100%;
    height: 55px;
    align-items: center;
    position: relative;
    &.phone {
        padding: 9px 10px;
    }
    &.error {
        border: 1px solid red;
    }
    input {
        width: 100%;
        padding-right: 20px;
        font-family: Inter-Regular;
    }
`;
const CountryDiv = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
`;
const Flag = styled.span`
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position: center;
    background-image: ${({ flag }) => flag && `url(${flag})`};
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-right: 5px;
    img {
        width: 100%;
        display: block;
    }
`;
const Code = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #2e2e2e;
    font-family: Inter-Regular;
`;
// const CountryDiv = styled.div``;
const DistrictName = styled.div``;
const SelectedLeadDiv = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-radius: 8px;
    position: relative;
    padding-right: 15px;
    background: #fafafa;
    overflow: hidden;
    &.error {
        border: 1px solid red;
    }
`;
const SelectedLead = styled.div`
    width: 100%;
    height: 56px;
    position: relative;
    background: #fafafa;
    small {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        font-size: 14px;
    }
`;
const TeamLeadDropdown = styled.div`
    position: absolute;
    width: 100%;
    padding: 15px;
    height: 300px;
    overflow-y: scroll;
    box-shadow: -4px 4px 30px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    top: 60px;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    background-color: #fff;
    transition: 150ms ease all;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9ad2af;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        top: 80px;
    }
`;
const TeamLeadDropBox = styled.div`
    height: calc(100% - 40px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const LocalBodySearch = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #f4f4f4;
    input {
        flex: 1;
        width: 100%;
        height: 100%;
        border: 1px solid #ddf4d5;
        padding: 5px;
        border-radius: 5px;
    }
`;
const SearchIcon = styled.span`
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-60%);
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const TeamLeadDropBoxDiv = styled.div``;
const DistrictDrop = styled.div`
    position: absolute;
    width: 100%;
    padding: 15px;
    height: 300px;
    overflow-y: scroll;
    box-shadow: -4px 4px 30px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    top: 60px;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    background-color: #fff;
    transition: 150ms ease all;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9ad2af;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        top: 80px;
    }
`;
const LocalbodyDropDown = styled.div`
    position: absolute;
    width: 100%;
    padding: 15px;
    height: 260px;
    overflow-y: scroll;
    box-shadow: -4px 4px 30px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    top: 60px;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    background-color: #fff;
    transition: 150ms ease all;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9ad2af;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        top: 80px;
    }
`;
const WardDropDown = styled.div`
    position: absolute;
    width: 100%;
    padding: 15px;
    height: 260px;
    overflow-y: scroll;
    box-shadow: -4px 4px 30px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    top: 50px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    background-color: #fff;
    transition: 150ms ease all;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9ad2af;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        top: 60px;
    }
`;
const LoaderDiv = styled.div`
    font-family: Inter-Regular;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const DistName = styled.div`
    padding: 16px 20px;
    border-radius: 8px;
    font-family: Inter-Regular;
    &:hover {
        background-color: #e8f5f0;
    }
    &.active {
        background-color: #e8f5f0;
    }
`;
const InputField = styled.input`
    font-family: Inter-Regular;
    font-size: 14px;
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
const IconEye = styled.span`
    display: inline-block;
    width: 17px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const IconArrow = styled.span`
    display: inline-block;
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px; */
    width: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    img {
        width: 100%;
        display: block;
    }
    &.rotate {
        transform: rotate(-180deg);
    }
`;

const FooterButton = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 15px;
    @media all and (max-width: 980px) {
        justify-content: center;
    }
`;
const CancelButton = styled.h3`
    font-size: 14px;
    background: #ffffff;
    border-radius: 5px;
    padding: 6px 15px;
    color: #000000;
    border: 1px solid #0fa76f;
    margin-right: 20px;
    font-family: Inter-Medium;
    cursor: pointer;
`;
const SubmitButton = styled.h2`
    font-size: 14px;
    cursor: pointer;
    background: #0fa76f;
    border-radius: 5px;
    padding: 6px 15px;
    color: #ffff;
    font-family: Inter-Medium;
    width: 80px;
`;

const LeadBox = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 60px;
    padding: 0 10px;
    border-radius: 6px;

    &:hover {
        background-color: #ededed;
    }
    &.selected {
        background-color: #ededed;
    }
`;
const SelectedLeadBox = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 55px;
    padding: 0 10px;
    border-radius: 6px;
`;
const LeadProfile = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`;
const Profile = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: ${({ image }) => image && `url(${image})`};
    background-size: 100%;
    background-position: center;
    img {
        display: block;
        width: 100%;
    }
`;
const AvatarDiv = styled.span`
    display: inline-block;
`;
const LeadDetails = styled.div``;
const LeadName = styled.h4`
    font-size: 14px;
    color: #333;
    text-transform: capitalize;
    font-family: Inter-Regular;
    margin-bottom: 5px;
`;
const UserType = styled.span`
    font-size: 13px;
    text-transform: capitalize;
    color: #969696;
`;
