import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function ProgramCounts({counts, manager, profile}) {
    return (
        <MainContainer>
            <Container>
                { manager !== 'program_assistant' && (<WinnersCountDiv to={`/winners?chief_user_pk=${profile.pk}`}>
                    <Left>
                        <Icon>
                            <img
                                src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/winner-medal.svg'
                                alt="Medal"
                            />
                        </Icon>
                        <p>Winners</p>
                    </Left>
                    <Right>
                        <p>{counts.winners ? counts.winners : "0"}</p>
                        <Arrow>
                            <img
                                src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg'
                                alt="Arrow"
                            />
                        </Arrow>
                    </Right>
                </WinnersCountDiv>)}
                <WinnersCountDiv to={`/participants?chief_user_pk=${profile.pk}`}>
                    <Left>
                        <Icon>
                            <img
                                src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/participants-icon.svg'
                                alt="Medal"
                            />
                        </Icon>
                        <p>Participants</p>
                    </Left>
                    <Right>
                        <p>{counts.participants ? counts.participants : "0"}</p>
                        <Arrow>
                            <img
                                src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg'
                                alt="Arrow"
                            />
                        </Arrow>
                    </Right>
                </WinnersCountDiv>
                <WinnersCountDiv to={`/applications?chief_user_pk=${profile.pk}`}>
                    <Left>
                        <Icon>
                            <img
                                src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/applications-icon.svg'
                                alt="Medal"
                            />
                        </Icon>
                        <p>Applications</p>
                    </Left>
                    <Right>
                        <p>{counts.applications ? counts.applications : "0"}</p>
                        <Arrow>
                            <img
                                src='https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg'
                                alt="Arrow"
                            />
                        </Arrow>
                    </Right>
                </WinnersCountDiv>
            </Container>
        </MainContainer>
    );
}

export default ProgramCounts;
const MainContainer = styled.div`
    background: #fff;
    border-radius: 16px;
    border: 1px solid #d3d3d3;
    padding: 10px;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
`;
const WinnersCountDiv = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    border-radius: 12px;
    padding: 10px;
    background-color: #fafafa;
    cursor: pointer;
    &:hover {
        border: 1px solid rgba(15, 167, 111, 0.24);
        background-color: #fffaf0;
    }
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    p {
        font-size: 16px;
        font-family: "Inter-Regular";
    }
`;
const Icon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    padding: 8px;
    margin-right: 15px;
    img {
        display: block;
        width: 100%;
    }
`;
const Right = styled.div`
    display: flex;
    align-items: center;

    p {
        font-size: 16px;
        color: #333;
        margin-right: 15px;
    }
`;
const Arrow = styled.span`
    display: inline-block;
    height: 15px;
    img {
        height: 100%;
        display: block;
    }
`;
