import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";

import WinnersTableHeader from "../includes/winners/WinnersTableHeader";
import { COLORS, textStyle } from "../utils/constants";
import { axiosConfig } from "../../axiosConfig";
import { Context } from "../context/Store";
import SectionLoader from "../includes/loaders/SectionLoader";
import { useSearchParams } from "react-router-dom";
import PageTitle from "../includes/PageTitle";
import { useNavigate } from "react-router-dom";
import DateRangeModal from "../includes/modals/DateRangeModal";
import moment from "moment";
import GreenNovationTableHead from "../includes/applications/GreenNovationTableHead";
import GreenNovationStudentDetails from "../includes/applications/GreenNovationStudentDetails";

function GreenovationApplications() {
    const [searchWord, setSearchWord] = useState("");
    const [paginationData, setPaginationData] = useState({});
    const [studentDetails, setStudentDetails] = useState([
        {
            id:1,
            name: "test",
            phone: "0909090909",
            student_class: "6",
            student_division: "A",
            school_name: "Test School",
            category: "Both",
            program: "quiz"
        },
        {
            id:2,
            name: "test",
            phone: "0909090909",
            student_class: "6",
            student_division: "A",
            school_name: "Test School",
            category: "Group",
            program: "Idea Pitching"
        },
        {
            id:3,
            name: "test",
            phone: "0909090909",
            student_class: "6",
            student_division: "A",
            school_name: "Test School",
            category: "Both",
            program: "Quiz & Idea Pitching"
        },
        {
            id:4,
            name: "test",
            phone: "0909090909",
            student_class: "6",
            student_division: "A",
            school_name: "Test School",
            category: "Single",
            program: "quiz"
        },
    ]);
    const [isLoading, setLoading] = useState(false);
    const { state } = useContext(Context);
    const access_token = state.user_data.access_token;
    const profile_pk = state.user_data.profile_pk;
    const role = state.user_data.role;
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);

    const [dateModal, setDateModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const navigate = useNavigate();
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };
    const updateUserPK = () => {
        setSearchParams({
            ...currentParams,
            chief_user_pk: profile_pk,
        });
    };
    const getFilterParams = () => {
        let filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));
        searchParams.get("district_pk") &&
            (filterParams.district_pk = searchParams.get("district_pk"));
        searchParams.get("localbody_pk") &&
            (filterParams.localbody_pk = searchParams.get("localbody_pk"));
        searchParams.get("ward_pk") &&
            (filterParams.ward_pk = searchParams.get("ward_pk"));
        searchParams.get("student_type") &&
            (filterParams.student_type = searchParams.get("student_type"));
        searchParams.get("student_class") &&
            (filterParams.student_class = searchParams.get("student_class"));
        searchParams.get("chief_user_pk") &&
            (filterParams.chief_user_pk = searchParams.get("chief_user_pk"));

        return filterParams;
    };

    const updateDateFilter = () => {
        let start_Date = moment(startDate).format("DD-MM-YYYY");
        let end_Date = moment(endDate).format("DD-MM-YYYY");
        const newUrl = {
            ...currentParams,
            start_date: start_Date,
            end_date: end_Date,
        };
        delete newUrl["days"];
        setSearchParams(newUrl);
        setDateModal(false);
    };

    useEffect(() => {
        if (
            role !== "program_head" ||
            role !== "msf_program_head" ||
            role !== "green_innovation_scholarship_head"
        ) {
            updateUserPK();
        }
    }, []);
    useEffect(() => {
        const params = getFilterParams();
        var page;
        params.page && (page = parseInt(params.page - 1));
        params.page && handlePageClick({ selected: page });
    }, []);

    const NextLabel = () => {
        return (
            <img
                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
                alt="Prev Arrow"
            />
        );
    };
    const PrevLabel = () => {
        return (
            <img
                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/left-arrow.svg"
                alt="Prev Arrow"
            />
        );
    };
    useEffect(() => {
        setLoading(true);
        const params = getFilterParams();
        axiosConfig
            .get("/programs/applications/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then((response) => {
                const { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetails(data);
                    setPaginationData(pagination_data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }, [searchParams]);

    return (
        <MainContainer id="main">
            <DateRangeModal
                setModal={setDateModal}
                modal={dateModal}
                setEndDate={setEndDate}
                endDate={endDate}
                startDate={startDate}
                setStartDate={setStartDate}
                onSubmit={updateDateFilter}
            />
            <PageTitle
                tab="Home"
                name="Applications"
                designation="New"
                section="Applications"
            />
            <SectionContainer>
                <Container>
                    <TableHeader>
                        <WinnersTableHeader
                            table={false}
                            searchWord={searchWord}
                            setSearchWord={setSearchWord}
                            filterType="applications"
                            setDateModal={setDateModal}
                            setDateRangeModal={setDateModal}
                            greenovation={true}
                        />
                    </TableHeader>
                    <TableHead>
                        <GreenNovationTableHead />
                    </TableHead>
                    {isLoading ? (
                        <EmptyBox>
                            <SectionLoader />
                        </EmptyBox>
                    ) : studentDetails.length > 0 ? (
                        <StudentDetailsList>
                            <GreenNovationStudentDetails
                                studentDetails={studentDetails}
                                pagination={paginationData}
                            />
                        </StudentDetailsList>
                    ) : (
                        <EmptyBox>No Data Found!</EmptyBox>
                    )}
                    {paginationData.total_pages > 1 &&
                        studentDetails.length > 0 && (
                            <PaginationDiv>
                                <PageCount>
                                    {paginationData.last_item} of{" "}
                                    {paginationData.total_items} Results
                                </PageCount>
                                <PageLink>
                                    <ReactPaginate
                                        forcePage={currentPage - 1}
                                        nextLabel={<NextLabel />}
                                        previousLabel={<PrevLabel />}
                                        pageRangeDisplayed={1}
                                        onPageChange={handlePageClick}
                                        className="pagination"
                                        pageClassName="pagination__li"
                                        activeClassName="pagination__li__active"
                                        breakClassName="pagination__break__li"
                                        breakLinkClassName="pagination__break__a"
                                        previousClassName="pagination__previous__li"
                                        previousLinkClassName="pagination__previous__a"
                                        nextClassName="pagination__next__li"
                                        nextLinkClassName="pagination__next__a"
                                        disabledClassName="pagination_disabled_btns"
                                        pageCount={paginationData.total_pages}
                                    />
                                </PageLink>
                            </PaginationDiv>
                        )}
                </Container>
            </SectionContainer>
        </MainContainer>
    );
}

export default GreenovationApplications;

const MainContainer = styled.div``;
const HeaderComponent = styled.div`
    h3 {
        margin-bottom: 10px;
    }
`;
const TitleTab = styled.div`
    margin-bottom: 24px;
    h4 {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 18px;
        font-family: "Inter-Regular";
    }
    span {
        margin: 0 6px;
        color: #333;
        opacity: 0.5;
        &:first-child {
            margin-left: 0;
        }
        &.bold {
            opacity: 1;
        }
    }
`;

const SectionContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    overflow-x: scroll;
    margin-top: 20px;
    &::-webkit-scrollbar-thumb {
        background-color: ${COLORS.steyp.primary};
        border-radius: 5px;
    }
    &::-webkit-scrollbar {
        height: 3px;
        width: 3px;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    @media all and (max-width: 1280px) {
        min-width: 1200px;
    }
`;
const TableHeader = styled.div``;
const TableHead = styled.div``;
const StudentDetailsList = styled.div`
    min-height: 300px;
`;
const PaginationDiv = styled.div`
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
`;
const PageLink = styled.div``;
const PageCount = styled.p`
    font-size: 14px;
    font-family: "Inter-Medium";
    color: #aaa;
`;
const EmptyBox = styled.div`
    min-height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: "Inter-Medium";
`;
