import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";


function GreenNovationStudentDetails({ studentDetails, pagination }) {

    const navigate = useNavigate();
    const handleClick = (e, student) => {
        if(student.program === 'quiz'){
            e.preventDefault()
        } else {
            navigate(`${student.pk}`)
        }
    }

    return (
        <SectionContainer>
            {studentDetails.map((student, index) => (
                <RowDiv key={index} onClick={(e) => handleClick(e,student)} cur={student.program !== 'quiz' ? true: false}>
                    {pagination?.first_item <= pagination?.last_item && (
                        <Columns type="no">
                            {pagination?.first_item + index}
                        </Columns>
                    )}
                    <Columns type={"name"}>
                        <p>{student.name ? student.name : "--"}</p>
                        <p>{student.phone ? student.phone : "--"}</p>
                    </Columns>
                    <Columns type={"school"}>
                        {student.school_name ? student.school_name : "--"}
                    </Columns>
                    <Columns type={"class"}>
                        {student.student_class ? student.student_class : "-"}{" "}
                        {student.student_division
                            ? student.student_division
                            : "-"}
                    </Columns>
                    <Columns type={"program"}>
                        {student.program
                            ? student.program.replace("_", " ")
                            : "--"}
                    </Columns>
                </RowDiv>
            ))}
        </SectionContainer>
    );
}

export default GreenNovationStudentDetails;

const SectionContainer = styled.div``;
const RowDiv = styled.div`
    cursor: ${({cur}) => cur && 'pointer'};
    padding: 0 16px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    @media all and (max-width: 480px) {
        padding: 0 10px;
    }
`;
const Columns = styled.div`
    text-transform: capitalize;
    width: ${({ type }) =>
         type == "no"
            ? "5%"
            : type == "name"
            ? "20%"
            : type == "school"
            ? "18%"
            : type == "class"
            ? "18%"
            : type == "division"
            ? "18%"
            : type == "category"
            ? "12%"
            : type == "program"
            ? "12%"
            : "15%"};
    color: #333;
`;
