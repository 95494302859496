import { useEffect, useRef } from "react";

const UseOutsideClick = ({ children, onClickOutside }) => {
    const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return <div ref={containerRef}>{children}</div>;
};

export default UseOutsideClick;