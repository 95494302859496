import React from "react";
import styled from "styled-components";
import { saveAs } from "file-saver";
import { download } from "downloadjs";

function CongratulationsCard() {
    const examStatus = {
        status: "not-completed",
    };
    const downloadImage = (imageUrl) => {
        download(imageUrl, "Winner.jpg");
    };
    return (
        <MainContainer>
            {examStatus.status === "completed" ? (
                <Container>
                    <BgImg>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-03-2023/winnerposter.png"
                            alt="Poster"
                        />
                    </BgImg>
                    <DotsDiv
                        onClick={() => {
                            downloadImage(
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-03-2023/winnerposter.png"
                            );
                            console.log("Download");
                        }}
                    >
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/download-white.svg"
                            alt="Dots"
                        />
                    </DotsDiv>
                    <StudentPhoto>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/user1.svg"
                            alt="Profile"
                        />
                    </StudentPhoto>
                    <DetailDiv>
                        <StudentName>Darshana Suresh</StudentName>
                        <WardName>Akampadam ward</WardName>
                    </DetailDiv>
                </Container>
            ) : (
                <ExamContainer>
                    <Center>
                        <Badge>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/first-place-banner.svg"
                                alt="Badge"
                            />
                        </Badge>
                        <Text>The winner will be announced on</Text>
                        <Date>5, May 2023</Date>
                    </Center>
                </ExamContainer>
            )}
        </MainContainer>
    );
}

export default CongratulationsCard;
const MainContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Container = styled.div`
    position: relative;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;
const BgImg = styled.div`
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        display: block;
    }
`;
const DotsDiv = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    cursor: pointer;
    img {
        width: 90%;
        display: block;
    }
`;
const StudentPhoto = styled.span`
    display: inline-block;
    position: absolute;
    top: 32%;
    width: 24%;
    img {
        width: 100%;
        display: block;
    }
`;
const DetailDiv = styled.div`
    position: absolute;
    bottom: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    @media all and (max-width: 1180px) {
        bottom: 15px;
    }
`;
const StudentName = styled.h4`
    margin-bottom: 7px;
    font-size: 19px;
    width: 100%;
    text-align: center;
    @media all and (max-width: 1180px) {
        font-size: 17px;
    }
`;
const WardName = styled.span`
    text-transform: uppercase;
    padding: 7px 10px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.12);
    border: 1px solid #fff;
    backdrop-filter: blur(3.82);
    border-radius: 16px;
    font-size: 14px;
    width: 100%;
    text-align: center;
    @media all and (max-width: 1180px) {
        font-size: 13px;
    }
`;
const ExamContainer = styled.div`
    width: 100%;
    padding: 70px 0;
    border-radius: 16px;
    border: 2px solid #0fa76f;
    background-color: #fff;
`;
const Badge = styled.span`
    display: block;
    width: 130px;
    img {
        display: block;
        width: 100%;
    }
`;
const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Text = styled.p`
    color: #969696;
    font-family: "Inter-Regular";
    font-size: 15px;
    margin: 20px 0 6px 0;
`;
const Date = styled.p`
    font-family: "Inter-Medium";
    font-size: 20px;
    color: #0fa76f;
`;
