import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PageTitle from "../includes/PageTitle";
import { axiosConfig } from "../../axiosConfig";
import { useSearchParams, useParams } from "react-router-dom";
import { Context } from "../context/Store";

function GreenovationApplicationsSingle() {
    const { state } = useContext(Context);
    const access_token = state.user_data.access_token;
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [groupMembers, setGroupMembers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const {team_lead_pk} = useParams()

    const getFilterParams = () => {
        let filterParams = {};
        searchParams.get("tech_lead") &&
            (filterParams.tech_lead = searchParams.get("tech_lead"));

        return filterParams;
    };

    useEffect(() => {
        setLoading(true);
        axiosConfig
            .get(`programs/applications/group-members/`, {
                headers: { Authorization: `Bearer ${access_token}` },
                params: {team_lead:team_lead_pk},
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setGroupMembers(data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, []);

    // const group_members = [
    //     {
    //         id: 1,
    //         name: "John Doe",
    //         phone: "0000000000",
    //         school_name: "test school",
    //         student_division: "A",
    //         student_class: "7",
    //         program: "quiz",
    //     },
    //     {
    //         id: 2,
    //         name: "John Doe",
    //         phone: "0000000000",
    //         school_name: "test school",
    //         student_division: "A",
    //         student_class: "7",
    //         program: "quiz",
    //     },
    //     {
    //         id: 4,
    //         name: "John Doe",
    //         phone: "0000000000",
    //         school_name: "test school",
    //         student_division: "A",
    //         student_class: "7",
    //         program: "quiz",
    //     },
    // ];
    const seperateChars = (name) => {
        const words = name.split(" ");
        const extractedChars = words.map((word) => word.slice(0, 1));
        const result = extractedChars.join("");
        return result;
    };
    return (
        <MainContainer id="main">
            <PageTitle
                tab="Home"
                name="Applications"
                designation="Group Members"
                section="Applications"
            />
            <SectionContainer>

                {groupMembers.length > 0 ?  groupMembers.map((member) => (
                    <Container>
                        <NameSection>
                            <CharName>{seperateChars(member.name)}</CharName>
                            <NameDetail>
                                <h1>{member.name}</h1>
                                <h2>{member.phone}</h2>
                            </NameDetail>
                        </NameSection>
                        <StudentDetails>
                            <Details>
                                <span>School</span>
                                <Name>{member.school_name}</Name>
                            </Details>

                            <Details>
                                <span>Class</span>
                                <Name>{member.student_class}</Name>
                            </Details>

                            <Details>
                                <span>Division</span>
                                <Name>{member.student_division}</Name>
                            </Details>

                            <Details>
                                <span>Program</span>
                                <Name>{member.program ? member.program.replace("_", " "): ""}</Name>
                            </Details>
                        </StudentDetails>
                    </Container>
                )):(
                    <EmptyBox>No Members Found!</EmptyBox>
                )}
            </SectionContainer>
        </MainContainer>
    );
}

export default GreenovationApplicationsSingle;

const EmptyBox = styled.div`
    width: 100%;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 20px;
`
const MainContainer = styled.div``;
const SectionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;
const Container = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    width: calc(50% - 15px);
    margin-top: 30px;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const NameSection = styled.div`
    display: flex;
    align-items: center;
`;
const CharName = styled.span`
text-transform: uppercase;
    font-size: 20px;
    background-color: #0fa76f;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
`;
const NameDetail = styled.div`
text-transform: capitalize;
    margin-left: 20px;
    h1 {
        font-size: 16px;
        color: #000;
        font-family: "Inter-Medium";
        margin-bottom: 10px;
    }
    h2 {
        font-size: 13px;
        color: #333;
        font-family: "Inter-Medium";
    }
`;
const StudentDetails = styled.div`
    margin-top: 20px;
`;
const Details = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    span {
        font-size: 14px;
        font-family: "Inter-Medium";
    }
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        span {
            margin-bottom: 5px;
        }
    }
`;
const Name = styled.h4`
    font-size: 16px;
    color: #000;
    font-family: "Inter-Medium";
    text-transform: capitalize;
`;
