import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Routes } from 'react-router-dom'
import Loader from '../../../includes/loaders/Loader'
const Login = lazy(() => import('../../../screens/authentication/Login'))

const AuthRouter = () => {
  return (
    <Suspense fallback={<Loader w={90} h={90} />}>
      <Routes>
        <Route path="/login" element={<Login />} />
      </Routes>
    </Suspense>
  )
}

export default AuthRouter
