import React, { Suspense, useState, lazy, useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Context } from "../../context/Store";
import DrawerMenu from "../../includes/general/DrawerMenu";
import Header from "../../includes/general/Header";
import Loader from "../../includes/loaders/Loader";
import ParticipantsSingle from "../../includes/participants/ParticipantsSingle";
import ProgramAssistantsSingle from "../../screens/teams/ProgramAssistantsSingle";
import ProgramAssociatesSingle from "../../screens/teams/ProgramAssociatesSingle";
import ProgramExecutivesSingle from "../../screens/teams/ProgramExecutivesSingle";
import ProgramManagerSingle from "../../screens/teams/ProgramManagerSingle";
import OfflinePage from "../../includes/modals/OfflinePage";
import GreenovationApplications from "../../screens/GreenovationApplications";
import GreenovationApplicationsSingle from "../../screens/GreenovationApplicationsSingle";

const GreeNovation = lazy(() =>
    import("../../screens/dashboard/GreeNovation")
);
const MsfDashboard = lazy(() =>
    import("../../screens/dashboard/MsfDashboard")
);
const ScholarshipDashboard = lazy(() =>
    import("../../screens/dashboard/ScholarshipDashboard")
);
const TeamInnerRouter = lazy(() => import("../routes/TeamInnerRouter"));
const LocationsInnerRouter = lazy(() =>
    import("../routes/LocationsInnerRouter")
);
const WinnersTable = lazy(() => import("../../screens/WinnersTable"));
const ParticipantsTable = lazy(() => import("../../screens/ParticipantsTable"));
const ApplicationsTable = lazy(() => import("../../screens/ApplicationsTable"));
const QuestionsDashboard = lazy(() =>
    import("../../screens/questions/QuestionsDashboard")
);

const AppRouter = () => {
    const {
        state: {
            user_data: { role },
        },
        dispatch,
    } = useContext(Context);

    const [openMenu, setOpenMenu] = useState(true);
    const [isOffline, setOffline] = useState(false);

    const [itemChange, setItemChange] = useState(
        window.innerWidth <= 980 ? true : false
    );
    const handleOnlineEvent = (e) => {
        reloadWebPage();
        setOffline(false);
    };
    const handleOfflineEvent = (e) => {
        setOffline(true);
    };

    const reloadWebPage = () => {
        window.location.reload();
    };
    useEffect(() => {
        if (navigator.onLine) {
            setOffline(false);
        } else {
            setOffline(true);
        }
        window.addEventListener("offline", handleOfflineEvent);
        window.addEventListener("online", handleOnlineEvent);
    }, []);

    const [Itemactive, setItemActive] = useState("");

    const [active, setActive] = useState("dashboard");
    return (
        <>
            <OfflinePage
                isOffline={isOffline}
                setOffline={setOffline}
                reloadWebPage={reloadWebPage}
            />
            <DrawerMenu
                Itemactive={Itemactive}
                setItemActive={setItemActive}
                setOpenMenu={setOpenMenu}
                openMenu={openMenu}
                setItemChange={setItemChange}
                itemChange={itemChange}
                setActive={setActive}
                active={active}
            />
            <Header openMenu={openMenu} />
            <Suspense fallback={<Loader w={90} h={90} />}>
                <Routes>
                    <Route path="/" element={<Navigate to="/dashboard/" />} />
                    <Route
                        path="dashboard/"
                        element={
                            role === 'msf_program_head' ? (
                                <MsfDashboard />
                            ):
                            role === "program_head" ? (
                                <ScholarshipDashboard />
                            ) : role === "program_manager" ? (
                                <ProgramManagerSingle />
                            ) : role === "program_executive" ? (
                                <ProgramExecutivesSingle />
                            ) : role === "program_associate" ? (
                                <ProgramAssociatesSingle />
                            ) : role === "green_innovation_scholarship_head" ? (
                                <GreeNovation />
                            ): (
                                <ProgramAssistantsSingle />
                            )
                        }
                    />
                    <Route path="winners/" element={<WinnersTable />} />
                    <Route
                        path="applications/"
                        element={
                            role === 'green_innovation_scholarship_head' ? (<GreenovationApplications />): (<ApplicationsTable />)
                        }
                    />
                    <Route
                        path="applications/:team_lead_pk/"
                        element={
                            role === 'green_innovation_scholarship_head' ? (<GreenovationApplicationsSingle />): null
                        }
                    />
                    <Route
                        path="participants/"
                        element={<ParticipantsTable />}
                    />
                    <Route
						path="participants/:id/"
						element={<ParticipantsSingle />}
					/>
                    <Route path="team/*" element={<TeamInnerRouter />} />
                    <Route
                        path="locations/*"
                        element={<LocationsInnerRouter />}
                    />
                    <Route path="questions/" element={<QuestionsDashboard />} />
                    <Route
                        path="/questions/?subject=mathematics&category=up"
                        element={<QuestionsDashboard />}
                    />
                </Routes>
            </Suspense>
        </>
    );
};

export default AppRouter;
