import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

function PeopleCard({ programers, dashboard, location, setEditModal, setSelectedUser, setEditUser }) {
    return (
        <BottomContainer>
            <ListContainer>
                {programers.map((manager, index) => (
                    <Users key={index} dashboard={dashboard}>
                        <UsersCard>
                            <IconEditor
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEditModal(true)
                                    setSelectedUser(manager.pk)
                                    setEditUser((prev) => !prev)
                                }}
                            >
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/edit-pen.svg"
                                    alt="Icon"
                                />
                            </IconEditor>
                            {manager.photo ? (
                                <ProfilePhoto>
                                    <img src={manager.photo} alt="Icon" />
                                </ProfilePhoto>
                            ) : (
                                <AvatarBox>
                                    <Avatar
                                        round={true}
                                        name={manager?.name}
                                        textSizeRatio={0.5}
                                        size="48"
                                    />
                                </AvatarBox>
                            )}

                            <NextArrow>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/circle-arrow.svg"
                                    alt="Icon"
                                />
                            </NextArrow>
                        </UsersCard>
                        <UserName> {manager.name}</UserName>
                        <RightSection
                        to={`${location}${manager.pk}/`}
                           
                        ></RightSection>
                    </Users>
                ))}
            </ListContainer>
        </BottomContainer>
    );
}

export default PeopleCard;

const BottomContainer = styled.div`
    width: 100%;
`;
const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    @media all and (max-width: 1280px) {
        /* gap: 11px; */
    }
    @media all and (max-width: 1180px) {
        /* gap: 10px; */
    }
`;
const IconEditor = styled.span`
    width: 30px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    transition: 250ms ease all;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 768px) {
        opacity: 1;
        visibility: visible;
    }
    @media all and (max-width: 480px) {
        left: 15px;
    }
    @media all and (max-width: 360px) {
        left: 10px;
    }
`;
const RightSection = styled(Link)`
    position: absolute;
    top: 0;
    width: 74%;
    right: 0;
    bottom: 0;
`;
const NextArrow = styled.span`
    width: 30px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
    transition: 150ms ease all;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 768px) {
        opacity: 1;
        visibility: visible;
    }
    @media all and (max-width: 480px) {
        right: 15px;
    }
    @media all and (max-width: 360px) {
        right: 10px;
    }
`;
const Users = styled.div`
    cursor: pointer;
    border: 2px solid #e2e2e2;
    border-radius: 8px;
    position: relative;
    width: ${({ dashboard }) =>
        dashboard ? "calc(16% - 2px)" : "calc(25% - 9px)"};
    padding: 18px 12px;
    &:hover {
        opacity: 1;
        border: 2px solid #0fa76f;
        ${IconEditor} {
            display: inline-block;
            left: 15px;
            opacity: 1;
            visibility: visible;
        }
        ${NextArrow} {
            display: inline-block;
            right: 15px;
            opacity: 1;
            visibility: visible;
        }
        /* cursor: pointer; */
    }
    @media all and (max-width: 1435px) {
        width: ${({ dashboard }) =>
            dashboard ? "calc(18% - 25px)" : "calc(33% - 5px)"};
    }
    /* @media all and (max-width: 1360px) {
		width: ${({ dashboard }) =>
        dashboard ? "calc(18% - 25px)" : "calc(26% - 15px)"};
	} */
    @media all and (max-width: 1280px) {
        width: ${({ dashboard }) => (dashboard ? "calc(18% + 10px)" : "")};
    }
    @media all and (max-width: 1180px) {
        width: ${({ dashboard }) =>
            dashboard ? "calc(18% + 10px)" : "calc(50% - 5px)"};
    }
    @media all and (max-width: 1080px) {
        width: ${({ dashboard }) => (dashboard ? "calc(22% + 16px)" : "")};
    }
    @media all and (max-width: 980px) {
        width: ${({ dashboard }) =>
            dashboard ? "calc(21% + 23px)" : "calc(22% + 16px)"};
    }
    @media all and (max-width: 940px) {
        width: ${({ dashboard }) =>
            dashboard ? "calc(27% + 31px)" : "calc(29% + 19px)"};
    }
    @media all and (max-width: 768px) {
        width: ${({ dashboard }) =>
            dashboard ? "calc(30% + 10px)" : "calc(34% - 11px)"};

        &:hover {
            opacity: 1;
            border: 2px solid #0fa76f;
            ${IconEditor} {
                left: 10px;
            }
            ${NextArrow} {
                right: 10px;
            }
            /* cursor: pointer; */
        }
    }
    @media all and (max-width: 640px) {
        width: ${({ dashboard }) =>
            dashboard ? "calc(45% + 15px)" : "calc(51% - 11px)"};
    }
    @media all and (max-width: 480px) {
        width: ${({ dashboard }) =>
            dashboard ? "calc(100% + 0px)" : "calc(106% - 17px)"};
    }
`;
const UsersCard = styled.div`
    align-items: flex-start;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
`;

const ProfilePhoto = styled.div`
    margin-bottom: 10px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        display: block;
    }
`;
const AvatarBox = styled.div`
    margin-bottom: 10px;
    & div.sb-avatar__text {
        div {
            font-size: 18px !important;
        }
    }
`;
const UserName = styled.h5`
    text-align: center;
    font-size: 16px;
    color: #000;
    font-family: Inter-Medium;
    text-transform: capitalize;
    @media all and (max-width: 1080px) {
        font-size: 15px;
    }
`;
