import React from "react";
import styled from "styled-components";
import OutLineButton from "./OutLineButton";

function FilledButton({ icon, title }) {
    return (
        <>
            <ShareButton icon={icon} title={title}>
                <ShareButtonImage title={title}>
                    <img src={icon} />
                </ShareButtonImage>
                {title && <Text>{title} </Text>}
            </ShareButton>
        </>
    );
}

export default FilledButton;

const ShareButton = styled.span`
    padding: ${({ icon, title }) => (icon && title ? "8px 12px" : "6px 5px")};
    background-color: #0fa76f;
    box-shadow: 0px 8px 9px rgba(15, 167, 111, 0.29);
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    @media all and (max-width: 480px) {
        padding: ${({ icon, title }) => (icon && title ? "7px 7px" : "6px 5px")};
    
}
`;
const ShareButtonImage = styled.span`
    margin-right: ${({ title }) => (title ? "5px" : "")};
    display: inline-block;
    img {
        display: block;
        width: 100%;
    }
`;
const Text = styled.span`
    font-size: 15px;
    font-family: Inter-Regular;
    color: #fff;
`;
