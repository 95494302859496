import React, { useState } from "react";
import styled from "styled-components";
import FilledButton from "./FilledButton";
import OutLineButton from "./OutLineButton";
import { download } from "downloadjs";
import { WhatsappShareButton } from "react-share";
import SectionLoader from "../loaders/SectionLoader";
import axios from "axios";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

function ShareScholarship({ posters, isLoading }) {
    const handleClick = (imageUrl) => {
        saveAs(imageUrl, "Posters");
    };
    
    const shareToWhatsapp = (ImageURL) => {
        console.log("ImageURLImageURL", ImageURL);
        const whatsappURL = `whatsapp://send?text=${encodeURIComponent(
            ImageURL
        )}`;
        window.open(whatsappURL, "_blank");
    };

    return (
        <MainContainer>
            <ContentBox>
                <MainHeading>Share Scholarship Detalis</MainHeading>
                <Container>
                    <PostersDiv>
                        {isLoading ? (
                            <LoaderBox>
                                <SectionLoader />
                            </LoaderBox>
                        ) : posters.length > 0 ? (
                            <>
                                {" "}
                                {posters.map((poster, index) => (
                                    <MiddleDiv key={index}>
                                        <Poster id="poster">
                                            <img
                                                id="imageid"
                                                src={poster.poster}
                                                alt=""
                                            />
                                        </Poster>
                                        <ButtonBox>
                                            <DownloadButton
                                                onClick={() => {
                                                    handleClick(poster.poster);
                                                }}
                                            >
                                                <OutLineButton
                                                    icon={
                                                        require("../../../assets/icons/download.svg")
                                                            .default
                                                    }
                                                />
                                            </DownloadButton>
                                            {/* <WhatsappShareButton
                                                url={poster.poster}
                                                title="Posters"
                                                separator=" "
                                            > */}
                                            {/* <ShareButton
                                                onClick={() => {
                                                    shareToWhatsapp([
                                                        poster.poster,
                                                    ]);
                                                }}
                                            >
                                                <FilledButton
                                                    icon={
                                                        require("../../../assets/icons/share.svg")
                                                            .default
                                                    }
                                                />
                                            </ShareButton> */}
                                            {/* </WhatsappShareButton> */}
                                        </ButtonBox>
                                    </MiddleDiv>
                                ))}
                            </>
                        ) : (
                            <EmptyBox>No Data Found!</EmptyBox>
                        )}
                    </PostersDiv>
                </Container>
            </ContentBox>
        </MainContainer>
    );
}

export default ShareScholarship;

const MainContainer = styled.section`
	padding: 0px 0px;
`;
const ContentBox = styled.div`
	background: #fff;
	border-radius: 16px;
	border: 1px solid #e2e2e2;
`;
const Container = styled.div`
	width: 100%;
	min-height: 240px;
	max-width: 58vw;
	&::-webkit-scrollbar {
		display: none;
	}
	@media all and (max-width: 1440px) {
		max-width: 58vw;
	}
	@media all and (max-width: 980px) {
		max-width: calc(100vw - 114px);
		margin: 0 auto;
	}
	@media all and (max-width: 768px) {
		max-width: calc(100vw - 104px);
		margin: 0 auto;
	}
	@media all and (max-width: 640px) {
		max-width: calc(100vw - 32px);
	}
`;
const MainHeading = styled.h1`
	font-size: 16px;
	color: #000;
	font-family: Inter-Medium;
	padding: 15px;
	border-bottom: 1px solid #e2e2e2;
`;
const MiddleDiv = styled.div`
	position: relative;
	width: 170px;
	margin-right: 15px;
	&:last-child {
		margin-right: 0;
	}
`;
const ButtonBox = styled.div`
	display: flex;
	position: absolute;
	bottom: 10px;
	right: 10px;
`;
const DownloadButton = styled.span`
	text-decoration: none;
	display: inline-block;
	/* margin-right: 10px; */
	&:hover {
		opacity: 1;
	}
`;
const Poster = styled.div`
	width: 170px;
	border: 1px solid #d3d3d3;
	border-radius: 8px;
	overflow: hidden;
	img {
		width: 100%;
		display: block;
	}
`;
const LoaderBox = styled.div`
	display: flex;
	width: 100%;
	min-height: 240px;
	align-items: center;
	justify-content: center;
`;
const PostersDiv = styled.div`
	width: 97%;
	display: flex;
	padding: 15px 0;
    margin: 0 auto;
	overflow-x: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
`;
const EmptyBox = styled.div`
	min-height: 180px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-family: "Inter-Medium";
`;
const ShareButton = styled.span``;
