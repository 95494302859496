import React from "react";
import styled from "styled-components";

function GreenNovationTableHead() {
    return (
        <SectionContainer>
            <RowDiv>
                <Columns type={"no"}>No</Columns>
                <Columns type={"name"}>Name</Columns>
                <Columns type={"school"}>School</Columns>
                <Columns type={"class"}>Class</Columns>
                <Columns type={"program"}>Program</Columns>
            </RowDiv>
        </SectionContainer>
    );
}

export default GreenNovationTableHead;

const SectionContainer = styled.div``;
const RowDiv = styled.div`
    border-bottom: 1px solid #e2e2e2;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    color: #7f7d7d;
    font-family: Inter-Medium;
`;
const Columns = styled.div`
   
   width: ${({ type }) =>
        type == "no"
            ? "5%"
            : type == "name"
            ? "20%"
            : type == "school"
            ? "18%"
            : type == "class"
            ? "18%"
            : type == "category"
            ? "12%"
            : type == "program"
            ? "12%"
            : "15%"};
    font-family: "Inter-Medium";
`;
